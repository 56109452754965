.recent-trade-main {
    .recent-trade-stocks-name {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        opacity: 0.800000011920929;
    }

    .recent-trade-stocks-amt {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.7px;
    }

    .recentrade-modal-div {
        width: 31px;
        height: 31px;
        top: 4px;
        border-radius: 8px;
        background-color: #1f1f1f;
        cursor: pointer;
    }

    .side-notification {
        height: 849px;
        border-radius: 25px 0px 0px 25px;
        background-color: var(--pf-primary-white-ffffff, #fff);
        bottom: 4rem;
        box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.06);
        padding-top: 2rem;
        padding-left: 2.2rem;
        overflow-y: auto;

        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #1f1f1f;
            border-radius: 20px;
            border: 3px solid transparent;
        }
    }

    .idle-box {
        background-color: var(--pf-primary-white-ffffff, #fff);
        border-radius: 20px;
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        width: 180px;
        height: 250px;
    }

    .userImage {
        width: 53px;
        height: 53px;
    }
    .twitter-img {
        width: 13px;
        height: 10.563px;
    }
    .twitter-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 500;
    }

    .stock-ticker {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
    }
    .verify-img {
        width: 13px;
        height: 13px;
    }
}
