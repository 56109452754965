@import 'global_scss/media.scss';

.trade-option-market-order {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    background-color: rgb(255, 255, 255);

    .heading-content {
        margin-left: 9rem;
    }

    .robesHood {
        width: 100%;
        height: 70px;
        background: rgb(245, 248, 248);
        border-radius: 14px;
        margin-left: 1rem;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Trade Option Market Order Modal'] {
    @include devices(largeMobile) {
        width: 95% !important;
        .heading-content,
        .robesHood {
            margin-left: 0rem;
        }
    }
}
