@import 'global_scss/media.scss';
.searchIcons-lower {
    width: 10%;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    @include devices(largeMobile) {
        margin-top: 0rem;
        width: 40%;
        position: absolute;
        right: 21px;
        top: 64px;
    }
}
