@import 'global_scss/media.scss';

.user-dashboard {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }

    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }

    .middle-container {
        top: -3rem;
        @include devices(tablet) {
            top: 1rem;
        }
    }

    .person-followers {
        height: 116px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
        @include devices(largeMobile) {
            flex-direction: column;
            height: 200px;
        }
        .person-img-user {
            width: 68px;
            height: 68px;
            top: 5px;
            border-radius: 85px 0px 85px 85px;
        }
    }

    .person-followers-end-user {
        height: 116px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
        @include devices(largeMobile) {
            flex-direction: column;
            height: 250px;
        }
    }

    .position-pl {
        width: 100%;
    }
    .top-trades {
        margin-left: 2rem;
        margin-right: 2rem;
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }

    .position-trader-container {
        width: 100%;
        border-radius: 20px;
        background-color: #fff;
        @include devices(largeMobile) {
            height: auto;
        }
    }

    .stock-list {
        @include devices(largeMobile) {
            margin-top: 2rem;
        }
    }

    .followers_subscribers {
        width: 100%;
        margin-top: 30px;
        @include devices(largeMobile) {
            flex-wrap: wrap;
        }

        .subscription {
            width: 100%;
            height: 103px;
            border-radius: 20px;
            background-color: #e9ffad;
            margin-left: 3px;

            @include devices(largeMobile) {
                margin-bottom: 1rem;
            }
        }
    }

    .spotlight {
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }
    .todays-p-l {
        width: 321px;
        height: 80px;
        border-radius: 14px;
        background-color: #d3ff5b;
        @include devices(largeMobile) {
            width: 100%;
        }
    }
}

.support-ticket-dropdown {
    width: 577px;
    height: 59px;
    background: #f5f8f8;
    border: none;
    border-radius: 14px;
}

.subscription-arrow-div {
    width: 38px;
    height: 38px;
    border-radius: 48px;
    background-color: #fff;
    margin-top: 8px;
}

.subscription-influencer-name {
    color: #1f1f1f;
    font-size: 15px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
}

.subscription-div {
    width: 100%;
    height: 78px;
    background-color: #fff;
    border-radius: 18px;
    border: 1px solid #ecf2f1;
}

.congratulation-img {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.subscription-orange-icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 2rem;
    left: 2px;
}

.main-container {
    width: 50%;
    @include devices(largeMobile) {
        width: 100%;
        margin-top: 2rem;
    }
    .trending-container {
        border-radius: 20px;
        background-color: var(--pf-secondary-geyser-dae-5-e-3, #dae5e3);
        border: 8px solid var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8);
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        p {
            margin-bottom: 0px;
        }
        .logo-container {
            width: 20%;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .title-side {
            width: 80%;
            .ticker {
                color: #1f1f1f;
                font-size: 13px;
                font-family: Figtree;
                font-weight: 500;
                line-height: 125%;
                margin-left: 10px;
            }
            .sort-name {
                color: #1f1f1f;
                font-size: 11px;
                font-family: Figtree;
                position: absolute;
                top: 15px;
                left: 10px;
            }
        }
    }
}
.notFollowinAndSubscribing {
    font-family: Figtree;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
}

.explore-trade-btn {
    width: 201px;
    height: 42px;
    border-radius: 79px;
    font-family: figtree;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-align: center;
    background-color: #1f1f1f;
    color: #d3ff5b;
    cursor: pointer;
}
