@import 'global_scss/media.scss';

.subscription-plan {
    border-radius: 25px 25px 0px 0px;
    .plan-info {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 24px;
        margin-right: 25px;
    }
    .angle-icon {
        position: absolute;
    }
    .payment-method {
        color: rgb(143, 143, 143);
        font-style: normal;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        margin-left: 7px;
    }

    .debit-card {
        width: 364px;
        height: 60px;
        border-radius: 14px;
        background-color: rgb(236, 242, 241);
        .debit-card-icon {
            width: 33.984px;
            height: 22.466px;
        }
        .card-text {
            color: rgb(31, 31, 31);
            font-style: normal;
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
            margin-left: 7px;
        }
    }
    .coupon-code {
        width: 364px;
        height: 52px;
        border-radius: 12px;
        border: 1px solid var(--pf-secondary-geyser-dae-5-e-3, #dae5e3);
        background: rgb(245, 248, 248);
        .code-text {
            color: rgb(31, 31, 31);
            font-style: normal;
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
        }
        .applied-text {
            color: rgb(31, 31, 31);
            font-style: normal;
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
            opacity: 0.6;
        }
    }
    .user-image {
        width: 364px;
        height: 317px;
    }

    .continue-btn {
        width: 280px;
        color: rgb(192, 255, 2);
        background-color: rgb(31, 31, 31);
        border-radius: 54px;
        height: 52px;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='subscription plan'] {
    @include devices(1366) {
        height: 100vh;
    }
    @include devices(1024) {
        height: auto;
    }
    @include devices(largeMobile) {
        height: 95vh;
        width: 100% !important;
        .debit-card.mb-2 {
            width: auto;
            height: auto;
        }

        .subscription-plan .coupon-code {
            width: auto;
            height: auto;
        }

        .subscription-plan .user-image {
            width: 100%;
            height: auto;
        }
    }
}
