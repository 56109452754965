@import 'global_scss/media.scss';

.settings-container {
    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    button.accordion-button.collapsed {
        &:focus {
            box-shadow: unset;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: rgb(211, 255, 91);
        &:focus {
            box-shadow: unset;
        }
    }
    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }
}

.accept-btn {
    width: 100px;
    color: #1f1f1f;
    background-color: #d3ff5b;
    border-radius: 54px;
    height: 28px;
    border: 1px solid #d3ff5b;
    text-transform: uppercase;
    font-style: normal;
    font-size: 10px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    top: 5px;
    margin-right: 10px;
}

.decline-btn {
    width: 100px;
    color: #1f1f1f;
    background-color: #fff;
    border-radius: 54px;
    height: 28px;
    border: 1px solid #1f1f1f;
    text-transform: uppercase;
    font-style: normal;
    font-size: 10px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    top: 5px;
}

.accept-para {
    font-size: 18px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #1f1f1f;
    border-radius: 54px;
}

.influencerUsername {
    color: #8f8f8f;
    font-size: 10px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    cursor: pointer;
    bottom: 13px;
}

.influencerfullname {
    color: #1f1f1f;
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.slick-prev,
.slick-next {
    display: none !important;
}

.hide-stocks {
    width: 38px;
    height: 22px;
    cursor: pointer;
}

.become-influencer-icon {
    font-size: 24px;
    color: #8f8f8f;
}

.become-influencer-div {
    width: 321px;
    height: 64px;
    border-radius: 14px;
    top: 17rem;
    cursor: pointer;
}

.become-influencer-para {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 7px;
}

.redeem-div {
    width: 321px;
    height: 64px;
    border-radius: 14px;
    top: 17rem;
    cursor: pointer;
}

.redeem-para {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 7px;
}

.remove-analyst-img {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.remove-analyst-img-orange {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 2rem;
    left: 2px;
}

.analyst-username {
    color: #1f1f1f;
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.remove-analyst-block-btn {
    width: 91px;
    height: 30px;
    border-radius: 54px;
    border: 1px solid #1f1f1f;
    color: #1f1f1f;
    font-family: Figtree;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    background: #fff;
    font-size: 10px;
}

.remove-analyst-btn {
    width: 325px;
    height: 48px;
    border-radius: 95px;
    border: 1px solid #1f1f1f;
    color: #d3ff5b;
    font-family: Figtree;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    background: #1f1f1f;
    font-size: 14px;
    margin-left: 15px;
}

.remove-analyst-modal-parent-div {
    height: 171px;
    border-radius: 25px 25px 0px 0px;
}

.manage-analyst-navigation {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 7px;
}

.manage-analyst-para {
    color: #1f1f1f;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}

.become-analyst-para {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 7px;
}

.become-analyst-accordion {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 7px;
}

.sweet-alert-button {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.manage-analyst-trade-parent-div {
    height: 760px;
    border-radius: 25px;
    border: #ecf2f1;
    background-color: #fff;
    top: 1rem;
    overflow-y: auto;
}

.side-notification-settings {
    height: 849px;
    border-radius: 25px 0px 0px 25px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    bottom: 4rem;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
    padding-top: 2rem;
}

.update-trade-previous-btn {
    width: 30%;
    color: #d3ff5b;
    background-color: #1f1f1f;
    border-radius: 54px;
    height: 52px;
    letter-spacing: 0.7px;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    margin: 1rem;
}

.next-trade-previous-btn {
    width: 30%;
    color: #1f1f1f;
    background-color: #d3ff5b;
    border-radius: 54px;
    height: 52px;
    letter-spacing: 0.7px;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    margin: 1rem;
}
