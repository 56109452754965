* {
    font-family: 'Figtree';
}

.image-gallery-stocks {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 0;
    border: 5px solid none;
    justify-content: space-evenly;
    /* height: 200px; */
    /* border-radius: 25px; */
}

.image-gallery-item-stocks {
    flex: 0 0 calc(33.33%);
    max-width: calc(50.33%);
    /* height: 200px;  */
    position: relative;
    border-radius: 25px;
    cursor: pointer;
}

.stocks-news-img {
    width: 180px;
    height: 103px;
    border-radius: 10px;
    cursor: pointer;
}

.stocks-news-description {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 500;
    line-height: normal;
    word-wrap: break-word;
    white-space: initial;
}

.arrow-back-stocks {
    width: 24px;
    height: 24px;
    top: 5rem;
    cursor: pointer;
}
