.become-influencer-setting {
    .save-btn {
        width: 311px;
        color: rgb(211, 255, 91);
        background-color: rgb(31, 31, 31);
        border-radius: 54px;
        height: 52px;
        letter-spacing: 0.7px;
        font-style: normal;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
    }
}
