@import 'global_scss/media.scss';

.graph-card {
    margin-top: 1rem;
    border-radius: 25px;
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    background-color: var(--pf-primary-white-ffffff, #fff);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
    @include devices(mediumTablet) {
        min-width: 100%;
    }

    img {
        width: 400px;
        height: 200px;
        flex-shrink: 0;
        @include devices(largeMobile) {
            width: 78%;
            height: auto;
        }
    }

    .performance-text {
        color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
    }

    .performance-value {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 30px;
        font-family: Figtree;
        font-weight: 600;
    }
    .performance-select {
        width: 94px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        background-color: var(--pf-primary-white-ffffff, #fff);
    }

    .performance-profit-lossPercentage {
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        font-size: 15px;
    }
}
