@import 'global_scss/media.scss';

.search-input-model {
    .search-icon {
        position: absolute;
        left: 12px;
        z-index: 1;
        color: rgb(255, 255, 255);
        width: 20px;
        height: 20px;
        top: 15px;
        cursor: pointer;
    }
    .input-search {
        width: 100%;
        height: 52px;
        border-radius: 25px;
        background: rgb(65, 65, 65);
        color: rgb(255, 255, 255);
        padding-left: 40px;
        border: none;
    }
    .link-decoration {
        text-decoration: none;
    }
    .map-content {
        width: 675px;
        .right-flex {
            width: 675px;
            display: flex;
            align-items: flex-start;
            gap: 22px;
        }
        .logo-image-container {
            width: 48px;
            height: 48px;
            background: rgb(31, 31, 31);
            border-radius: 50%;
            overflow-y: auto;
        }
        .logo-image {
            width: 22.4px;
            height: 25.6px;
            border-radius: 50%;
        }
    }

    .name-container {
        margin-left: 10px;
        .ticker-para {
            color: rgb(31, 31, 31);
            font-size: 15px;
            font-family: Figtree;
            font-weight: 500;
            font-style: normal;
            line-height: normal;
        }
        .name-para {
            color: rgb(31, 31, 31);
            font-size: 12px;
            font-family: Figtree;
            font-weight: 400;
            font-style: normal;
            line-height: normal;
            opacity: 0.5;
        }
    }

    .return-text {
        color: rgb(31, 31, 31);
        font-size: 12px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 400;
        opacity: 0.8;
    }
    .arrow-image-container {
        width: 54.3px;
        height: 17px;
        border-radius: 48px;
        background: rgb(211, 255, 91);
        padding: 3px 10px;
    }

    .arrow-image-icon {
        width: 8.333px;
        height: 5px;
        margin-top: 4px;
        margin-right: 2px;
    }

    .present-text {
        color: rgb(31, 31, 31);
        font-size: 10px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.7px;
    }

    .image-container {
        display: flex;
        overflow-x: auto;
        height: 335px;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: 1rem;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='search input model'] {
    right: 1% !important;
    position: absolute;
    @include devices(1680) {
        // inset: 26% auto auto 76% !important;
    }
    @include devices(1366) {
        // inset: 36% auto auto 76% !important;
    }
    @include devices(mediumTablet) {
        width: 61% !important;

        .search-input-model .name-container .name-para {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 160px;
        }
        .search-input-model .map-content {
            width: auto;
        }
        .search-input-model .map-content .right-flex {
            width: auto;
        }
        .image-container {
            display: block;
        }
    }

    @include devices(largeMobile) {
        width: 92% !important;
        margin: auto;
    }
}
