@import 'global_scss/media.scss';

.trade-option-model {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    background-color: rgb(255, 255, 255);

    .stock-options {
        overflow-y: auto;
        @include devices(1366) {
            height: 300px;
        }
        & > div:last-child {
            margin-bottom: 70px;
        }
    }

    .stock-options::-webkit-scrollbar {
        display: none;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Trade Options Modal'] {
    @include devices(largeMobile) {
        width: 95% !important;
    }
}
