@import 'global_scss/media.scss';

.average-section {
    width: 406px;
    height: 91px;
    border-radius: 18px;
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    background-color: var(--pf-primary-white-ffffff, #fff);
    @include devices(largeMobile) {
        width: 100%;
        height: auto;
    }

    .average-gain-number {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        text-align: center;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
    }

    p.average-gain-label {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        text-align: center;
        font-size: 12px;
        font-family: Figtree;
        opacity: 0.5;
    }
}
