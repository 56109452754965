.dropdown-children-container {
    ::after {
        display: none;
    }

    .btn-check:checked + .btn,
    .btn.active,
    .btn.show,
    .btn:first-child:active,
    :not(.btn-check) + .btn:active {
        color: var(--bs-btn-active-color);
        background-color: transparent;
        border-color: transparent;
    }

    button {
        background-color: transparent;
        border: transparent;
        &:active,
        &:focus,
        &:hover {
            background-color: transparent;
            border: transparent;
        }
    }
}
