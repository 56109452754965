@import 'global_scss/media.scss';

.stock-detail-container {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }
    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    p.influencer-name {
        top: 0;
    }

    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }

    .middle-container {
        top: -3rem;
        @include devices(tablet) {
            top: 1rem;
        }
    }

    .today-stock-record {
        @include devices(tablet) {
            flex-direction: column;
        }
    }

    .trade-dropdown.dropdown-menu.show {
        background: black;
        padding: 1rem;
        border-radius: 25px;
    }
    .pending-order {
        width: 100%;
        height: 78px;
        background-color: var(--pf-primary-white-ffffff, #fff);
        border-radius: 18px;
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 1rem;

        @include devices(largeMobile) {
            height: auto;
            padding-bottom: 1rem;
            .d-flex.justify-content-between.m-1.w-100 {
                flex-wrap: wrap;
            }
        }
    }

    .flex-wrap-on-media {
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }

    .hide-on-mobile {
        @include devices(largeMobile) {
            display: none;
        }
    }

    .trade-position {
        @include devices(largeMobile) {
            flex-wrap: wrap;
        }
        .view-trade-position {
            width: 100%;
            margin-right: 1rem;
            @include devices(largeMobile) {
                margin-right: 0rem;
            }
        }

        .trade-history {
            width: 100%;
            margin-left: 1rem;
            @include devices(largeMobile) {
                margin-left: 0rem;
                margin-top: 1rem;
            }
        }
    }

    .company-info {
        @include devices(largeMobile) {
            flex-wrap: wrap;
        }
    }

    .today-hight-low-stock {
        @include devices(largeMobile) {
            flex-wrap: wrap;
        }

        .high-low-price {
            width: 100%;
            margin-right: 1rem;
            @include devices(largeMobile) {
                margin-right: 0rem;
            }
        }

        .prev-open-stock {
            margin-left: 1rem;
            @include devices(largeMobile) {
                margin-left: 0rem;
                margin-top: 1rem;
            }
        }
    }

    .stock-line-chart {
        height: 300px;
        background-size: contain !important;
        background-position: bottom !important;
        background-repeat: no-repeat !important;
        @include devices(1280) {
            height: 200px;
        }
    }

    .graph-days-buttons {
        width: 38px;
        height: 26px;
        border-radius: 8px;
        cursor: pointer;
        text-transform: uppercase;
        padding-left: 10px;
        padding-top: 2px;
    }
}

.sell-stock-btn {
    width: 180px;
    height: 46px;
    text-decoration: none;
    background-color: var(--pf-primary-canary-d-3-ff-5-b, #d3ff5b);
    border-radius: 54px;
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    border: none;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.7px;
    cursor: pointer;
}

.trade-history-parent-div {
    width: 100%;
    height: 78px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    border-radius: 18px;
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
}

.trade-img-div {
    width: 34px;
    height: 34px;
    background-color: #1f1f1f;
    border-radius: 50%;
}

.trade-symbl {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 14px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin-left: 10px;
}

.stock-side-style {
    margin-left: 10px;
    color: #71c901;
    text-transform: uppercase;
}

.trade-side-text-tradehistory {
    margin-left: 10px;
    color: #71c901;
    text-transform: uppercase;
}

.share-text {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 12px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.at-symbl {
    color: ' var(--pf-primary-black-1-f-1-f-1-f, #1F1F1F)';
    font-size: 12px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
}

.trade-price {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 12px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
}

.trade-amt {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 14px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: right;
}

.trade-date {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-size: 12px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
}

.trade-history-parent-div {
    width: 100%;
    height: 78px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    border-radius: 18px;
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
}

.trade-history-img-div {
    width: 34px;
    height: 34px;
    background-color: #1f1f1f;
    border-radius: 50%;
}

.trade-logo {
    width: 34px;
    height: 34px;
}

.trade-symbol {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 14px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    margin-left: 10px;
}

.trade-share {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 12px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.trade-at {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 12px;
    font-family: Figtree;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
}
