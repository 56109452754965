@import 'global_scss/media.scss';

.broker-graph-main {
    .broker-graph {
        margin-top: 1rem;
        @include devices(largeMobile) {
            flex-direction: column-reverse;
        }
    }

    .dropdown-children-container.show.dropdown {
        width: 100%;
    }
    button.dropdown-toggle.btn.btn-primary {
        padding: 0;
        width: 100%;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        color: unset;
        text-decoration: none;
        background-color: unset;
    }

    .dropdown-menu.show {
        width: 90%;
        margin: 1rem;
        margin-top: 0;
        margin-bottom: 0rem;
    }

    .broker-dropdown {
        .down-arrow {
            width: 36px;
            height: 36px;
            border-radius: 8px;
            border: 1px solid rgb(79 71 71);
            background: rgb(31, 31, 31);
            i {
                opacity: 0.5;
                color: rgb(255, 255, 255);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .dropdown-menu {
        .dropdown-item:not(:last-child) {
            border-bottom: 1px solid rgb(225 225 225);
        }
    }

    .broker-dropdown-item {
        span {
            img {
                width: 50px;
                height: 50px;
            }
        }
    }

    .all-brokers {
        background: rgb(31, 31, 31);
        border-radius: 20px 20px 0px 0px;
        width: 98%;
        height: 82px;
        margin-right: 1rem;

        @include devices(769) {
            &::before {
                content: '';
                width: 32px;
                height: 28px;
                border: 12px solid #1f1f1f;
                position: absolute;
                bottom: -11px;
                border-top: 0;
            }

            &::before {
                border-right: 0;
                border-radius: 0 0 0 44px;
                right: -21px;
            }
        }

        @include devices(largeMobile) {
            width: 100%;
            margin-right: 0rem;
        }
    }

    .graph-value-container {
        width: 100%;
        border-radius: 0px 20px 20px;
        background: rgb(31, 31, 31);
        margin-top: -2px;
        @include devices(largeMobile) {
            border-radius: 0px 0px 20px 20px;
        }
    }

    .graph-button-container {
        width: 40%;
        margin: auto;
        border-radius: 50px;
        padding: 5px;
        background-color: #3a3a3a;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 9%;
        margin: auto;
        z-index: 0.9;
        @include devices(largeMobile) {
            width: 100%;
            position: unset;
        }

        button {
            background-color: transparent;
            border-color: transparent;
            border-radius: 50px;
            padding: 0 15px;
            height: 33px;
        }
        .activeEarning {
            color: #3a3a3a;
            border-color: rgb(211, 255, 91);
            background-color: rgb(211, 255, 91);
        }
    }

    img.broker-portfolio-icon {
        width: 48px;
        height: 48px;
    }

    p.drop-down-para {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-family: Figtree;
        font-weight: 600;
        margin-left: 10px;
    }

    .brockrages-label {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        opacity: 0.5;
        margin-right: 30px;
    }

    .account-value-label {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
    }

    .performance-value-label {
        color: rgb(211, 255, 91);
        font-size: 30px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
    }
}
