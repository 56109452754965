@import 'global_scss/media.scss';

.order-info {
    height: 78px;
    border-radius: 18px;
    background: rgb(255, 255, 255);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 1rem;
    @include devices(largeMobile) {
        height: 138px;
    }
}

.order-info-contain {
    @include devices(largeMobile) {
        flex-wrap: wrap;
    }
}
