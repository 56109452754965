@import 'global_scss/media.scss';

.unsubscribe-user-dashboard {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }

    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        position: absolute;
        z-index: -1;
    }
    .header-content {
        .middle-flex {
            width: 91.5%;
            margin-left: auto;
            @include devices(1024) {
                width: 89%;
            }
            @include devices(tablet) {
                width: 95%;
            }
            @include devices(largeMobile) {
                flex-direction: column;
                width: 97%;
            }

            @include devices(mediumMobile) {
                padding-top: 0rem !important;
            }
        }
        .search-bar {
            margin-right: 0.5rem;
            @include devices(largeMobile) {
                margin-right: 1rem;
                margin-top: 4rem;
            }

            @include devices(mediumMobile) {
                .inputWidthWithNotToggle {
                    width: 340px;
                }
            }
        }

        .flex-after-middle {
            width: 64%;
            margin-left: inherit;
            left: 9rem;
            margin-top: 3rem;
            @include devices(1920) {
                left: 10rem;
            }

            @include devices(1440) {
                width: 63%;
                left: 7.8rem;
            }

            @include devices(1280) {
                width: 61%;
            }
            @include devices(1024) {
                width: 83%;
                left: 7rem;
            }

            @include devices(tablet) {
                width: 86%;
                left: 2.3rem;
            }
            @include devices(largeMobile) {
                width: 86%;
                left: 1.3rem;
            }

            @include devices(mediumMobile) {
                flex-direction: column;
                margin-top: 2rem;
                margin-left: auto;
                margin-right: auto;
                width: 58%;
                margin-bottom: 2rem !important;

                .d-flex.search-section {
                    margin-top: 3rem;
                }
            }
        }
    }

    .header-text-content {
        .header-welcome {
            color: #fff;
            font-size: 12px;
            font-family: 'Figtree';
            font-weight: 500;
            font-style: normal;
            line-height: normal;
            position: absolute;
            opacity: 0.5;
            white-space: nowrap;
        }
        .header-finance {
            color: white;
            font-size: 25px;
            font-family: 'Figtree';
            font-weight: 500;
            font-style: normal;
            line-height: normal;
            position: absolute;
            white-space: nowrap;
        }
    }

    .search-section {
        .right-rem {
            right: '2rem';
        }
        .search-icon {
            color: #fff;
            width: 20px;
            height: 20px;
            right: 5px;
            cursor: pointer;
        }
        .search-trader-input {
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            font-style: normal;
            background: #1f1f1f;
            border: none;
            width: 95px;
        }
        .filter-icon {
            width: 24px;
            height: 24px;
            margin-left: 5px;
            cursor: pointer;
        }
        .mobile-icon {
            width: 24px;
            height: 24px;
            margin-left: 10px;
            cursor: pointer;
        }
    }

    .featured {
        color: #8f8f8f;
        font-size: 16px;
        font-family: 'Figtree';
        font-weight: 500;
        position: absolute;
        font-style: normal;
        line-height: 24px;
        width: 100px;
        display: flex;
        flex-wrap: nowrap;
    }

    .verify-img {
        width: 15px;
        height: 15px;
    }
    .trader-text {
        color: #fff;
        font-size: 16px;
        font-family: 'Figtree';
        font-weight: 500;
        font-style: normal;
        line-height: 24px;
    }

    .card-text {
        color: #fff;
        font-size: 16px;
        font-family: 'Figtree';
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        padding-left: 0px;

        @include devices(tablet) {
            width: 53px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .card-button {
        width: 45%;
        height: 30px;
        border-radius: 40px;
        flex-shrink: 0;
        border: 1px solid var(--pf-primary-white-ffffff, #fff);
        color: #fff;
        margin-right: 1rem;
        @include devices(tablet) {
            width: 86px;
        }
    }

    .subscribe-btn {
        width: 45%;
        height: 30px;
        border-radius: 54px;
        flex-shrink: 0;
        border: 1px solid #d3ff5b;
        background-color: transparent;
        color: #d3ff5b;
        @include devices(tablet) {
            width: 86px;
        }
    }

    .green-section {
        @include devices(largeMobile) {
            flex-direction: column;
            padding: 6px !important;
            text-align: center;
        }
    }

    .brokerages-text {
        color: #1f1f1f;
        font-size: 16px;
        font-family: 'Figtree';
        font-weight: 400;
        line-height: 20px;
        @include devices(largeMobile) {
            margin-bottom: 8px;
            margin-top: 8px !important;
        }
    }

    .brokerages-btn {
        background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        color: rgb(211, 255, 91);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        width: 207px;
        height: 46px;
        border-radius: 54px;

        @include devices(tablet) {
            font-size: 10px;
            width: 169px;
            height: 40px;
        }

        @include devices(largeMobile) {
            margin: auto;
        }
    }

    .isMobile {
        @include devices(tablet) {
            display: none;
        }

        @include devices(769) {
            display: block;
        }
    }

    .brokerage-container {
        border-radius: 20px;
        background-color: rgb(211, 255, 91);
    }

    .influencer-img {
        width: 100%;
        border-radius: 20px;
        height: 300px;
    }
    .card-bottom-container {
        width: 100%;
        border-radius: 20px;
        background-color: rgba(31, 31, 31, 0.4);
        backdrop-filter: blur(37.5px);
        box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
        position: absolute;
    }
    .twitter-icon {
        width: 16px;
        height: 13px;
    }
    .twitter-text {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        padding-left: 5px;
    }
    .verify-icon {
        width: 13px;
        height: 13px;
        left: 5px;
        top: 4px;
    }
    .return-text {
        color: rgb(255, 255, 255);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
        opacity: 0.8;
    }
    .green-container {
        width: 65px;
        height: 19px;
        border-radius: 48px;
        background: rgb(211, 255, 91);
        margin-left: 8px;
    }
    .top-arrow-icon {
        width: 8.333px;
        height: 5px;
        margin-left: 4px;
        bottom: 2px;
    }
    .middle-present-text {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        letter-spacing: 0.7px;
        margin-left: 5px;
        padding-top: 1px;
    }
    .greenColor {
        background-color: rgba(255, 255, 255, 0.2);
    }
    .transparentColor {
        background-color: transparent;
    }
    .follow-text {
        text-align: center;
        font-size: 10px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.5px;
    }
    .subscribe-btn-text {
        text-align: center;
        font-size: 10px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.5px;
    }
    .side-notification-bar {
        height: 849px;
        border-radius: 25px 0px 0px 25px;
        background-color: var(--pf-primary-white-ffffff, #fff);
        box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
        padding-left: 2rem;
        padding-top: 2.2rem;
    }

    .card-content {
        height: 400px;
    }
}

.card-bottom-container2 {
    width: 100%;
    border-radius: 20px;
    background-color: rgba(31, 31, 31, 0.4);
    backdrop-filter: blur(37.5px);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
    bottom: 0;
    position: absolute;
}
