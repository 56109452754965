@import 'global_scss/media.scss';

.profile-from-influencer {
    height: 649px;
    flex-shrink: 0;
    border-radius: 25px 25px 25px 25px;
    border: 1px solid var(--ffffff, rgba(255, 255, 255, 0.1));

    .influencer-profile-pic {
        height: 649px;
        flex-shrink: 0;
        border-radius: 25px 25px 25px 25px;
        border: 1px solid var(--ffffff, rgba(255, 255, 255, 0.1));
        width: 100%;
    }
    .subscribe-button-details {
        position: absolute;
        bottom: 0%;
        margin-left: 0%;
    }
    .person-info {
        width: 100%;
        min-height: 150px;
        flex-shrink: 0;
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(37.5px);
        padding: 30px;
        @include devices(mediumTablet) {
            width: 100%;
        }

        @include devices(mediumMobile) {
            width: 87%;
        }
    }

    .subscribe-person-info {
        min-width: 74%;
        height: 60px;
        flex-shrink: 0;
        border-radius: 79px;
        margin-left: 120px;
        border: none;

        @include devices(mediumMobile) {
            min-width: 180px;
            height: 60px;
        }
    }

    .profile-orange-icon {
        max-width: 47px;
        height: 47px;
        flex-shrink: 0;
    }

    .user-name {
        font-size: 20px;
        font-family: Figtree;
        font-weight: 500;
        color: var(--pf-secondary-crusta-ff-792-e, #ff792e);
        margin-left: 10px;
    }

    .user-email {
        color: var(--pf-secondary-crusta-ff-792-e, #ff792e);
        font-size: 14px;
        font-family: Figtree;
        margin-left: 10px;
    }

    .edit-icon {
        width: 26px;
        height: 26px;
        cursor: pointer;
    }

    button.follow-state {
        width: 91px;
        height: 30px;
        border-radius: 54px;
        flex-shrink: 0;
        border: none;
    }

    .follow-text {
        text-align: center;
        font-size: 10px;
        font-family: Figtree;
        font-weight: 800;
        letter-spacing: 0.5px;
        border: none;
        margin-top: 7px;
    }

    .investment-pattern {
        padding: 6px 12px;
        gap: 5px;
        border-radius: 28px;
        color: var(--pf-primary-white-ffffff, #fff);
        font-size: 11px;
        font-family: Figtree;
        margin-left: 30px;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.4);
    }
    .per-month {
        position: absolute;
        top: 2px;
    }
    .amount-per-month {
        width: 99px;
        height: 62px;
        flex-shrink: 0;
        border-radius: 0px 50px 50px 0px;
        background-color: rgba(255, 255, 255, 0.1);
    }

    .amount-profile {
        color: var(--pf-primary-canary-d-3-ff-5-b, #d3ff5b);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 600;
    }

    .per-month-profile {
        color: var(--pf-primary-white-ffffff, #fff);
        font-size: 11px;
        font-family: Figtree;
    }

    .subscribe-text-flow {
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.7px;
    }
}
