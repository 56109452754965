@import 'global_scss/media.scss';

.scrolling-slider {
    .slider-info-container {
        border-radius: 20px;
        background-color: rgba(31, 31, 31, 0.4);
        backdrop-filter: blur(37.5px);
        box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
        position: absolute;
        bottom: 1px;
        width: 100%;
    }

    .slider-twitter-icon {
        width: 28.208px;
        height: 19.3px;
    }

    .slider-twitter-follower-count {
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        padding-left: 5px;
    }

    .slider-user-name {
        color: rgb(255, 255, 255);
        font-size: 23px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        padding-left: 5px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        max-width: 130px;
    }

    .slider-verify-img {
        width: 19.3px;
        height: 19.3px;
        margin-left: 5px;
    }

    .slider-return-label {
        color: rgb(255, 255, 255);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
        opacity: 0.8;
    }

    .slider-blue-area-contain {
        width: 78.8px;
        height: 24px;
        border-radius: 71.262px;
        background: rgb(211, 255, 91);
        margin-left: 8px;
        justify-content: center;
        align-items: center;
        img {
            height: 12px;
            width: 12px;
        }
    }

    .slider-return-value {
        color: rgb(31, 31, 31);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        letter-spacing: 0.7px;
        margin-left: 5px;
        margin-bottom: 0;
    }

    .follow-btn,
    .subscribe-btn {
        width: 149px !important;
        height: 46px !important;
        border-radius: 40px;
        flex-shrink: 0;
        padding: 4px 14px;
        text-align: center;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.5px;
        @include devices(largeMobile) {
            width: 128px !important;
        }
    }

    .subscribe-btn {
        border: 1px solid rgb(211, 255, 91);
        background-color: transparent;
        color: rgb(211, 255, 91);
    }

    .follow-btn {
        border: 1px solid var(--pf-primary-white-ffffff, #fff);
        background-color: transparent;
        color: rgb(255, 255, 255);
    }
}
