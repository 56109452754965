@import 'global_scss/media.scss';

.influencer-detail-largest-win {
    border-bottom: 1px solid rgb(239, 239, 239);
    padding-bottom: 1rem;

    .largest-win-block {
        width: 100%;
        height: 87px;
        border-radius: 18px;
        background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    }

    .largest-win-label {
        color: var(--pf-primary-white-ffffff, #fff);
        font-size: 22px;
        font-family: Figtree;
        font-weight: 500;
    }

    .grow-icon {
        width: 14px;
        height: 14px;
    }

    .largest-win-text {
        color: var(--pf-primary-white-ffffff, #fff);
        font-size: 14px;
        font-family: Figtree;
        margin-left: 5px;
        opacity: 0.5;
    }

    .breakeven-trades {
        width: 100%;
        height: 149px;
        border-radius: 18px;
        background-color: var(--pf-secondary-geyser-dae-5-e-3, #dae5e3);
    }

    .calender-icon {
        width: 41px;
        height: 46px;
        color: rgb(255, 255, 255);
    }

    .breakeven-number {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 30px;
        font-family: Figtree;
        font-weight: 500;
    }

    .breakeven-trades-label {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        opacity: 0.6;
    }

    .avgtrade-text {
        color: '#1F1F1F';
    }
}
