@import 'global_scss/media.scss';

.subscription-model {
    width: 490px;
    height: 171px;
    border-radius: 25px 25px 0px 0px;
    .heading {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
    }

    .plan-block-container {
        background-color: rgb(31, 31, 31);
        border-radius: 30px;
        width: 204px;
        height: 231px;
    }
    .diamond-img {
        width: 47px;
        height: 42.18px;
    }
    .influencer-amt {
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .per-month {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .plan-text {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        opacity: 0.4;
    }

    .lock-icon {
        width: 40px;
        height: 40px;
    }

    .holding-text {
        font-family: Figtree;
        color: rgb(31, 31, 31);
        font-size: 16px;
        line-height: 22px;
        font-style: normal;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
    .continue-btn {
        width: 325px;
        color: rgb(192, 255, 2);
        background-color: rgb(31, 31, 31);
        border-radius: 54px;
        height: 52px;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='subscription-model'] {
    @include devices(largeMobile) {
        width: 350px !important;
        height: 95vh !important;
        .subscription-model.p-3 {
            height: auto;
            width: auto;
        }

        .image.d-flex.justify-content-evenly.mt-3 {
            flex-direction: column;
            .d-flex {
                justify-content: center;
            }
        }
        ul {
            padding: 0;
        }
    }
}
