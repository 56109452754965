@import 'global_scss/media.scss';

.welcome-section {
    .middle-flex {
        width: 91.5%;
        margin-left: auto;
        @include devices(1024) {
            width: 89%;
        }
        @include devices(tablet) {
            width: 95%;
        }
        @include devices(largeMobile) {
            flex-direction: column;
            width: 97%;
        }

        @include devices(mediumMobile) {
            padding-top: 0rem !important;
        }
    }

    .search-bar {
        margin-right: 0.5rem;
        @include devices(largeMobile) {
            margin-right: 1rem;
            margin-top: 4rem;
        }

        @include devices(mediumMobile) {
            .inputWidthWithNotToggle {
                width: 340px;
            }
        }
    }

    .header-text-content {
        .header-welcome {
            color: #fff;
            font-size: 12px;
            font-family: 'Figtree';
            font-weight: 500;
            font-style: normal;
            line-height: normal;
            position: absolute;
            opacity: 0.5;
            white-space: nowrap;
        }
        .header-finance {
            color: white;
            font-size: 25px;
            font-family: 'Figtree';
            font-weight: 500;
            font-style: normal;
            line-height: normal;
            position: absolute;
            white-space: nowrap;
        }
    }

    .influencer-name {
        color: white;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 400;
        position: absolute;
        font-style: normal;
        line-height: 24px;
        top: 5rem;
        white-space: nowrap;
    }

    .flex-middle-lower-flex {
        display: flex;
        justify-content: space-between;
        width: 70%;
        @include devices(largeMobile) {
            flex-direction: column;
            width: 100%;
        }
    }
}
