@import 'global_scss/media.scss';

.username-text {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 22px;
    font-family: Figtree;
    font-weight: 500;
    margin-left: 15px;
    font-style: normal;
    line-height: normal;
}

.middle-line {
    height: 18px;
    margin-top: 10px;
    margin-left: 10px;
}

.analyst-text {
    color: #ff792e;
    font-size: 22px;
    font-family: Figtree;
    font-weight: 500;
    margin-left: 15px;
    font-style: normal;
    line-height: normal;
}

.follow-btn {
    width: 89px;
    height: 29px;
    margin-top: 25px;
    margin-bottom: 20px;
    border-radius: 54px;
    color: #d3ff5b;
    font-size: 10px;
    font-family: Figtree;
    font-weight: 600;
    // margin-left: 15px;
    font-style: normal;
    line-height: 12px;
    letter-spacing: 5%;
    background-color: #1f1f1f;
    // margin-left: 1px;
}

.followername-text {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 22px;
    font-family: Figtree;
    font-weight: 500;
    margin-left: 15px;
    font-style: normal;
    line-height: normal;
}

.follower-length {
    color: #1f1f1f;
    font-size: 12px;
    font-family: Figtree;
    position: absolute;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.followers-text {
    color: #1f1f1f;
    font-size: 12px;
    font-family: Figtree;
    position: absolute;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px;
}

.following-div {
    width: 100px;
    margin-left: 10px;
    margin-bottom: 10px;
}

.following-text {
    color: #1f1f1f;
    font-size: 12px;
    font-family: Figtree;
    position: absolute;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px;
}
