.accountPrivacyText {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 18px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.right-arrow-fa {
    width: 20px;
    height: 20px;
}
