@import 'global_scss/media.scss';

.trending-list {
    background: white;
    padding-left: 30px;
    padding-top: 30px;
    border-radius: 20px;
    .trending-header {
        color: rgb(31, 31, 31);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }
    .stock-container {
        width: 675px;
    }
    .data-container {
        background: rgb(245, 248, 248);
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        border-radius: 18px;
        width: 100px;
        height: 30px;
        padding-left: 5px;
        padding-top: 4px;
        cursor: pointer;
    }
    .trending-logos {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
    .ticker-text {
        color: rgb(31, 31, 31);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        padding-left: 5px;
    }
    .market-text {
        color: rgb(31, 31, 31);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }
    .learn {
        color: rgb(31, 31, 31);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }
    .view-all {
        color: rgb(100, 162, 255);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        right: 10px;
    }
    .dashboard-image {
        width: 212px;
        height: 147px;
    }
    .dashboard-image-container {
        right: 15px;
    }
    .news {
        color: rgb(31, 31, 31);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }
    .image-gallery {
        width: 100%;
    }
    .middle-gallery-container {
        margin-left: 20px;
    }
    .author-text {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .description {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        white-space: pre-wrap;
        font-style: normal;
        line-height: normal;
        opacity: 0.5;
    }

    .stocks-news-description {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        word-wrap: break-word;
        white-space: initial;
    }
    .nvda {
        color: rgb(100, 162, 255);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }
    .green-arrow {
        width: 6px;
        height: 4px;
    }
    .up-present {
        color: rgb(113, 201, 1);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }
    .bottom-dashboard-image {
        width: 100px;
        height: 100px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 10px;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Trending Lists'] {
    right: 1% !important;

    input.form-control.inputWidthWithNotToggle {
        width: 100%;
    }

    @include devices(mediumTablet) {
        height: auto !important;
    }
    @include devices(largeMobile) {
        width: 370px !important;
        left: 0 !important;
        right: 0 !important;
        margin: auto;
        .trending-list {
            padding-left: 0;
            padding: 20px;
            height: 492px;
            overflow-y: scroll;
            .dashboard-image {
                width: 153px;
                height: 120px;
            }
        }

        .trending-list .stock-container {
            width: auto;
        }
        .trending-list .dashboard-image-container {
            right: 0;
            overflow: auto;
            align-items: center;
            flex-wrap: wrap;
            img {
                margin-bottom: 1rem;
            }
        }
    }
}
.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 1000ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    z-index: 9999;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.market-section {
    text-align: center;
    h4 {
        font-size: 16px;
        color: #1f1f1f;
        font-weight: bold;
    }
    .dollar-value {
        font-size: 16px;
        color: #1f1f1f;
        margin-bottom: 0.5rem;
    }
    .percent-value {
        font-size: 16px;
        color: #71c901;
        margin: 0;
    }
}

.news-title {
    font-size: 14px;
    white-space: break-spaces;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.publishedUtc {
    font-size: 14px;
}
.tickers-map {
    color: #64a2ff;
    font-size: 12px;
    text-transform: uppercase;
}
