@import 'global_scss/media.scss';

.payment-confirmation {
    border-radius: 25px 25px 0px 0px;
    min-height: 458px;
    .angle-position {
        position: absolute;
    }
    .confirmation-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 24px;
        margin-right: 25px;
    }
    .payment-card-text {
        color: rgb(143, 143, 143);
        font-style: normal;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        margin-left: 7px;
    }
    .btn-master-card {
        width: 364px;
        height: 72px;
        border-radius: 14px;
        background-color: rgb(236, 242, 241);
    }
    .master-card-logo {
        width: 46px;
        height: 36px;
    }
    .code-text {
        color: rgb(31, 31, 31);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
        margin-left: 10px;
    }
    .expire-date {
        margin-left: 2px;
        .expiry-date-para,
        .dashed-text {
            color: rgb(31, 31, 31);
            font-style: normal;
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
        }

        .dashed-text {
            opacity: 0.2;
            margin-left: 10px;
        }
        .user-name {
            color: rgb(31, 31, 31);
            font-style: normal;
            font-size: 14px;
            font-family: Figtree;
            font-weight: 400;
            line-height: normal;
            opacity: 0.5;
            margin-left: 10px;
        }
    }

    .menu-container {
        width: 364px;
        height: 220px;
        border-radius: 18px;
        background: rgb(255, 255, 255);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 30px 100px 0px;
        .middle-container {
            padding-right: 2rem;
            padding-left: 2rem;
        }
        .middle-code-text {
            color: rgb(31, 31, 31);
            font-style: normal;
            font-size: 16px;
            font-family: Figtree;
            font-weight: 600;
            line-height: normal;
            margin-left: 10px;
        }
    }

    .input-check-bg {
        background: rgb(255, 255, 255);
        color: rgb(100, 162, 255);
    }
    .prize-container {
        width: 364px;
        height: 105px;
        border-radius: 20px;
        background-color: rgb(31, 31, 31);
        .debited-text {
            color: rgb(255, 255, 255);
            font-style: normal;
            font-size: 12px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
            opacity: 0.6;
        }
        .debit-amount {
            color: rgb(211, 255, 91);
            font-style: normal;
            font-size: 30px;
            font-family: Figtree;
            font-weight: 600;
            line-height: normal;
        }
        .monthly-plan {
            color: rgb(255, 255, 255);
            font-style: normal;
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
        }
        .make-payment-btn {
            width: 280px;
            color: rgb(192, 255, 2);
            background-color: rgb(31, 31, 31);
            border-radius: 54px;
            height: 52px;
            font-style: normal;
            font-size: 14px;
            font-family: Figtree;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.7px;
        }
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='payment confirmation'] {
    @include devices(largeMobile) {
        .payment-confirmation .btn-master-card {
            width: 320px;
        }
        .payment-confirmation .prize-container {
            width: 320px;
        }
    }

    @include devices(mobile) {
        .payment-confirmation .btn-master-card {
            width: 290px;
        }
        .payment-confirmation .prize-container {
            width: 290px;
        }
    }
}
