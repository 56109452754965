.win-rate-back {
    width: 100%;
    height: 121px;
    border-radius: 18px;
    background-color: var(--pf-additional-calamansi-e-9-ffad, #e9ffad);

    .win-rate-number {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 35px;
        font-family: Figtree;
        font-weight: 500;
    }

    .win-rate-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        opacity: 0.5;
    }
    .win-rate-img {
        width: 200px;
    }
}
