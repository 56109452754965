@import 'global_scss/media.scss';

.normal-user-spotlight {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }

    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
    }

    .header-content {
        .middle-flex {
            width: 91.5%;
            margin-left: auto;
            @include devices(1024) {
                width: 89%;
            }
            @include devices(tablet) {
                width: 95%;
            }
            @include devices(largeMobile) {
                flex-direction: column;
                width: 97%;
            }

            @include devices(mediumMobile) {
                padding-top: 0rem !important;
            }
        }
        .search-bar {
            margin-right: 0.5rem;
            @include devices(largeMobile) {
                margin-right: 1rem;
                margin-top: 4rem;
            }

            @include devices(mediumMobile) {
                .inputWidthWithNotToggle {
                    width: 340px;
                }
            }
        }

        .flex-after-middle {
            width: 64%;
            margin-left: inherit;
            left: 9rem;
            margin-top: 3rem;
            @include devices(1920) {
                left: 10rem;
            }

            @include devices(1440) {
                width: 63%;
                left: 7.8rem;
            }

            @include devices(1280) {
                width: 61%;
            }
            @include devices(1024) {
                width: 83%;
                left: 7rem;
            }

            @include devices(tablet) {
                width: 86%;
                left: 2.3rem;
            }
            @include devices(largeMobile) {
                width: 86%;
                left: 1.3rem;
            }

            @include devices(mediumMobile) {
                flex-direction: column;
                margin-top: 2rem;
                margin-left: auto;
                margin-right: auto;
                width: 58%;
                margin-bottom: 2rem !important;

                .d-flex.search-section {
                    margin-top: 3rem;
                }
            }
        }
    }

    .header-text-content {
        .header-welcome {
            color: #fff;
            font-size: 12px;
            font-family: 'Figtree';
            font-weight: 500;
            font-style: normal;
            line-height: normal;
            opacity: 0.5;
            white-space: nowrap;
            margin-bottom: 0;
        }
        .header-finance {
            color: white;
            font-size: 25px;
            font-family: 'Figtree';
            font-weight: 500;
            font-style: normal;
            line-height: normal;
            white-space: nowrap;
        }

        .spotlight-text {
            color: white;
            font-size: 18px;
            font-family: Figtree;
            font-weight: 400;
            position: absolute;
            font-style: normal;
            line-height: 24px;
            top: 5rem;
        }
    }

    .flex-after-middle {
        width: 64%;
        margin-top: 3rem;
        justify-content: end;
    }

    .isMobile {
        @include devices(tablet) {
            display: none;
        }

        @include devices(769) {
            display: block;
        }
    }

    .carousal-image-slide {
        height: 593px;
        border-radius: 30px;
    }

    .carousal-left,
    .carousal-right {
        font-size: 36px;
        color: black;
        position: absolute;
        bottom: 20rem;
    }

    .carousal-left {
        right: 150px;
        @include devices(largeMobile) {
            right: 0;
        }
    }

    .carousal-right {
        left: 200px;
        @include devices(largeMobile) {
            left: 0;
        }
    }
}
