/* PerformersOfTheWeek.css */

.performer-container {
    width: 100%;
    height: 90px;
    border-radius: 20px;
    background-color: var(--pf-secondary-geyser-dae-5-e-3, #dae5e3);
    border: 8px solid var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.performer-rank {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 18px;
    font-family: Figtree;
    font-weight: 600;
    margin: 0.65rem;
}

.middle-line {
    height: 38px;
}

.performer-avatar {
    width: 32.928px;
    height: 32.928px;
    margin: 0.25rem;
}

.orange-icon {
    width: 17.307px;
    height: 17.307px;
    position: absolute;
    top: 24px;
}

.performer-name {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 14px;
    font-family: Figtree;
    padding-top: 10px;
    padding-left: 10px;
}

.performer-username {
    margin: auto;
    margin-left: 10px;
}

.latest-equities {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 14px;
    font-family: Figtree;
}

.profit-label {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-size: 10px;
    font-family: Figtree;
    right: 6px;
}
.separator-performance-of-week {
    height: 100%;
    width: 1px;
    background-color: rgb(189, 189, 189);
    margin-right: 20px;
}
