@import 'global_scss/media.scss';

.login-carousal {
    .carousel-inner {
        position: relative;
        width: 75%;
        overflow: hidden;
        height: 100vh;
        margin: auto;
        @include devices(largeTablet) {
            width: 90%;
        }
    }
    .carousel-item {
        position: unset;
        height: 100%;
        margin-top: 9rem;
        @include devices(largeTablet) {
            margin-top: 3rem;
        }

        img {
            @include devices(largeTablet) {
                margin-top: 5rem;
            }
            @include devices(mediumMobile) {
                margin-top: 2rem;
            }
            @include devices(mobile) {
                margin-top: 0rem;
            }
        }
    }
    .carousel-indicators {
        right: unset;
        button {
            background-color: rgba(31, 31, 31, 1);
            width: 10px;
            height: 10px;
            border: unset;
            border-radius: 50px;
        }
        button[aria-current='true'] {
            background-color: rgba(31, 31, 31, 1);
            width: 30px;
        }
        @include devices(largeMobile) {
            margin-left: 6%;
        }
    }

    .carousel-caption {
        color: rgba(31, 31, 31, 1);
        position: absolute;
        right: 15%;
        bottom: unset;
        left: 15%;
        padding-top: unset;
        padding-bottom: unset;
        text-align: center;
        margin-top: 5rem;
        h2 {
            font-size: 32px;
            font-weight: 700;
        }
        p {
            font-size: 22px;
            font-weight: 400;
        }

        @include devices(largeMobile) {
            right: 0;
            left: 0;
        }
    }

    .carousel-control-prev {
        display: none;
    }
    .carousel-control-next {
        display: none;
    }
}
