@import 'global_scss/media.scss';

.modal-close-btn {
    background: #1f1f1f;
    border: 1px solid #1f1f1f;
    width: 20%;
    height: 2rem;
    font-size: 18px;
    color: #d3ff5b;
}

.modal-close-btn:hover {
    opacity: 0.7;
}

.modal-submit-btn {
    background: #d3ff5b;
    border: 1px solid #d3ff5b;
    width: 20%;
    height: 2rem;
    font-size: 18px;
}

.modal-submit-btn:hover {
    opacity: 0.7;
}

.main-container {
    height: auto;
    overflow: hidden;
    .first-block {
        @include devices(1024) {
            width: 100%;
        }
    }
    .form-container {
        @include devices(1024) {
            width: 100%;
        }
    }
}

/* Define the CSS for big screens (min-width: 1024px) */
