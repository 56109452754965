.manage-analyst-trade-heading {
    color: #1f1f1f;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
}

.analyst-trade-list-inner-parent {
    height: 7rem;
    border: 1px solid grey;
}

.add-analyst-trade-btn {
    width: 311px;
    color: #d3ff5b;
    background-color: #1f1f1f;
    border-radius: 54px;
    height: 52px;
    letter-spacing: 0.7px;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
}
