@import 'global_scss/media.scss';

.heading-wrapper {
    .recent-trades {
        color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        @include devices(largeMobile) {
            white-space: nowrap;
        }
    }

    .wrapper-margin-left {
        margin-left: 10px;
    }

    .wrapper-verified {
        color: var(--pf-secondary-malibu-64-a-2-ff, #64a2ff);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
    }

    img.wrapper-verified-icon {
        width: 17px;
        height: 17px;
    }

    .view-all-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        cursor: pointer;
        @include devices(largeMobile) {
            white-space: nowrap;
        }
    }
}
