html {
    scroll-behavior: smooth;
}

.scroll-sideNotification {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1f1f1f;
        border-radius: 20px;
        border: 3px solid transparent;
    }
}
