.edit-pricing-container {
    .per-month-container {
        background-color: rgb(31, 31, 31);
        border-radius: 20px;
        width: 70%;
        // height: 205px;
    }
    .diamond-icon-container {
        width: 55px;
        height: 55px;
    }
    .prize {
        color: rgb(255, 255, 255);
        font-size: 30px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .plan {
        color: rgb(255, 255, 255);
        font-size: 12px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .description {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .hr-line {
        width: 100%;
        opacity: 0.1;
    }

    .edit-prizing-input {
        color: rgb(31, 31, 31);
        font-size: 22px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        border: none;
        background: rgb(245, 248, 248);
        width: 100%;

        // &:focus {
        //     outline: unset;
        //     box-shadow: unset;
        // }

        text-align: center;
    }
    .input-connect {
        width: 70%;
        height: 59px;
        background: rgb(245, 248, 248);
        border-radius: 14px;
    }
    .btn-update {
        width: 70%;
        color: rgb(211, 255, 91);
        background-color: rgb(31, 31, 31);
        border-radius: 54px;
        height: 52px;
        letter-spacing: 0.7px;
        font-style: normal;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
        margin: 10;
    }
}
