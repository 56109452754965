@import 'global_scss/media.scss';

.line-input {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
}

.line-input:focus {
    border-color: #80bdff;
    box-shadow: none;
}

#codeInput {
    width: 100%;
    height: 200px;
    font-family: monospace;
    font-size: 14px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.user-registration-number {
    height: auto;
    overflow: hidden;
    .first-block {
        @include devices(1024) {
            width: 100%;
        }
    }
    .react-tel-input .flag-dropdown {
        left: 0;
    }
    .react-tel-input .form-control {
        width: 100%;
        height: 50px;
        &:focus {
            outline: unset;
            border-color: transparent;
            box-shadow: unset;
        }
    }

    .react-tel-input {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
    }

    .parrot-logo {
        text-align: center;
    }

    .confirm-btn {
        width: 100%;
        background-color: rgb(192, 255, 2);
        color: black !important;
        height: 2.5rem;
    }
}

.user-registration-otp {
    height: auto;
    overflow: hidden;
    .first-block {
        @include devices(1024) {
            width: 100%;
        }
    }
    .otp-input {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        background: transparent;
        border-color: black;
        border-radius: 0;
        &:focus {
            border-color: black;
            box-shadow: unset;
        }
    }

    .confirm-btn {
        width: 100%;
        background-color: rgb(192, 255, 2);
        font-weight: bold;
        color: black !important;
        height: 2.5rem;
        border: none;
    }

    .resend-btn {
        width: 50%;
        background-color: transparent;
        color: blue !important;
        height: 2.5rem;
        border-width: 0px;
        border: none !important;
    }

    .confirm-btn {
        opacity: 0.7;
    }

    .password-field {
        position: relative;
        display: flex;
        align-items: center;
    }

    .password-field.col i {
        position: absolute;
        right: 13px;
        top: 30%;
    }
}

.skipStyle {
    cursor: pointer;
}

.main-container-signup {
    height: auto;
    overflow: hidden;
    .first-block {
        @include devices(1024) {
            width: 100%;
        }
    }
    .form-container {
        @include devices(1024) {
            width: 100%;
        }
    }
}

.scroll-avatar {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1f1f1f;
        border-radius: 20px;
        border: 3px solid transparent;
    }
}

.sign-up-user-image {
    width: 135px;
    height: 135px;
    border-radius: 200px;
}

.sign-up-scroller-profile-image {
    width: 100%;
    border-radius: 25px 25px 20px;
    background-color: rgb(218, 229, 227);
}

.sign-up-select-avatar-label {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
}

.sign-up-map-image {
    display: inline-block;
    margin: 0px 10px;
    text-align: center;
    img {
        width: 86px;
        height: 86px;
        border-radius: 50%;
        background: rgb(255, 255, 255);
        cursor: pointer;
    }
}

.sign-in-update-avatar-btn {
    width: 70%;
    color: rgb(192, 255, 2);
    background-color: rgb(31, 31, 31);
    border-radius: 54px;
    height: 52px;
    letter-spacing: 0.7px;
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
}
