@import 'global_scss/media.scss';

.set-time-in-force {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    background-color: rgb(255, 255, 255);
    .heading-content {
        margin-left: 6rem;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Set Time In Force Modal'] {
    @include devices(largeMobile) {
        width: 95% !important;
        .heading-content {
            margin-left: 0;
        }
    }
}
