@import 'global_scss/media.scss';

.person-followers {
    height: 116px;
    border-radius: 20px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
    @include devices(largeMobile) {
        flex-direction: column;
        height: 200px;
    }

    .person-img-user {
        width: 68px;
        height: 68px;
        top: 5px;
        border-radius: 85px 0px 85px 85px;
    }

    .todays-p-l {
        width: 321px;
        height: 80px;
        border-radius: 14px;
        background-color: var(--pf-primary-canary-d-3-ff-5-b, #d3ff5b);
        @include devices(largeMobile) {
            width: 100%;
        }
    }

    .portfolio-username {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 22px;
        font-family: Figtree;
        font-weight: 500;
        margin-left: 15px;
        font-style: normal;
        line-height: normal;
        width: 200px;
    }

    .person-followers-user-image {
        width: 52px;
        border-radius: 26px;
    }

    .portfolio-username-container {
        width: 140px;
        padding-top: 5px;
    }

    .user-follower-count {
        color: rgb(31, 31, 31);
        font-size: 16px;
        font-family: Figtree;
        position: absolute;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 7px;
    }

    .user-follower-label {
        color: rgb(31, 31, 31);
        font-size: 12px;
        font-family: Figtree;
        position: absolute;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 10px;
        margin-left: 15px;
    }

    .middle-line-container {
        height: 38px;
        margin-top: 35px;
        right: 48px;
        img {
            height: 18px;
            margin-top: 10px;
        }
    }

    .following-count {
        margin-top: 34px;
        width: 100px;
        right: 37px;
    }

    .user-follower-switch {
        width: 28px;
        height: 28px;
        border-radius: 6px;
        background-color: var(--pf-secondary-malibu-64-a-2-ff, #64a2ff);
        margin-top: 10px;
        img {
            width: 14.56px;
            height: 16.8px;
        }
    }

    .todaysPL-label {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        margin-left: 10px;
        font-style: normal;
        line-height: normal;
        margin-top: 15px;
    }

    .profit-loss-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 30px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        padding-top: 5px;
    }

    .percentage-btn-switch {
        width: 30px;
        height: 30px;
        color: white;
        font-weight: 500;
        font-size: 13px;
        border: none;
        background-color: var(--pf-secondary-malibu-64-a-2-ff, #64a2ff);
        &:focus {
            background-color: var(--pf-secondary-malibu-64-a-2-ff, #64a2ff);
        }

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
