.subscribe-analytics-card {
    width: 100%;
    height: 182px;
    border-radius: 20px;
    background: rgb(233, 255, 173);
    margin-right: 1rem;

    .analytics {
        width: 23px;
        height: 23px;
    }
    .arrow-up-right-outline {
        width: 16px;
        height: 16px;
        margin-left: 3rem;
        cursor: pointer;
    }

    .subscriber-logo {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        background: rgb(255, 255, 255);
    }

    .subscriber-count {
        color: rgb(31, 31, 31);
        font-size: 35px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }
    .subscriber-label {
        color: rgb(31, 31, 31);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
    }
}
