.redeem-parent-div {
    width: 500px;
    height: 200px;
    background-color: #f5f8f8;
    border-radius: 14px;
}

.refer-input {
    border-radius: 8px;
    border: none;
    width: 200px;
}
.redeem-code-btn {
    width: 325px;
    height: 52px;
    border-radius: 54px;
    background: #1f1f1f;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    color: #d3ff5b;
    letter-spacing: 0.7px;
}
