@mixin devices($breakpoint) {
    @if $breakpoint == 1920 {
        @media only screen and (min-width: 1920px) {
            @content;
        }
    }
    @if $breakpoint == 1680 {
        @media only screen and (min-width: 1680px) {
            @content;
        }
    }

    @if $breakpoint == 1600 {
        @media only screen and (min-width: 1600px) {
            @content;
        }
    }

    @if $breakpoint == 1440 {
        @media only screen and (max-width: 1440px) {
            @content;
        }
    }
    @if $breakpoint == 1366 {
        @media only screen and (max-width: 1366px) {
            @content;
        }
    }

    @if $breakpoint == 1280 {
        @media only screen and (max-width: 1280px) {
            @content;
        }
    }

    @if $breakpoint == 1024 {
        @media only screen and (max-width: 1024px) {
            @content;
        }
    }
    @if $breakpoint == largeTablet {
        @media only screen and (max-width: 992px) {
            @content;
        }
    }

    @if $breakpoint == mediumTablet {
        @media only screen and (max-width: 820px) {
            @content;
        }
    }

    @if $breakpoint == 769 {
        @media only screen and (min-width: 769px) {
            @content;
        }
    }

    @if $breakpoint == tablet {
        @media only screen and (max-width: 768px) {
            @content;
        }
    }

    @if $breakpoint == largeMobile {
        @media only screen and (max-width: 420px) {
            @content;
        }
    }

    @if $breakpoint == mediumMobile {
        @media only screen and (max-width: 376px) {
            @content;
        }
    }
    @if $breakpoint == mobile {
        @media only screen and (max-width: 360px) {
            @content;
        }
    }
}
