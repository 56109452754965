@import 'global_scss/media.scss';

.share-data {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 500;
    line-height: normal;
    width: 100px;
    border: none;
    margin-left: 30%;
}

.mimic-modal-parent-div {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    background-color: #fff;
}

.modal1-parent-div {
    background: #ecf2f1;
    padding-left: 2rem;
    padding-right: 2rem;
}

.mimicking-para {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 500;
    line-height: 24px;
}

.market-order {
    color: var(--pf-primary-black-1-f-1-f-1-f, #64a2ff);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.hr-style {
    opacity: 0.10000000149011612;
    width: 100%;
    bottom: 5px;
}

.robesHood-container {
    background-color: #f5f8f8;
    border-radius: 20px;
}

.get-img {
    width: 65px;
    height: 60px;
    border-radius: 60%;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 5px;
}

.first-name-para {
    color: #1f1f1f;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 140%;
}

.modal1-username {
    color: #1f1f1f;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 140%;
    opacity: 0.5;
    bottom: 10px;
}

.symbol-text {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 600;
    line-height: 125%;
}

.handle-buy-btn {
    width: 150px;
    height: 52px;
    color: var(--pf-primary-canary-d-3-ff-5-b, #d3ff5b);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    border-radius: 54px;
    letter-spacing: 0.7px;
    background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
}

.edit-btn {
    width: 150px;
    height: 52px;
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    border-radius: 54px;
    letter-spacing: 0.7px;
    background-color: var(--pf-primary-white-ffffff, #fff);
}

.robinhood-txt {
    color: #1f1f1f;
    font-style: normal;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 500;
    line-height: normal;
}

.robin-img-modal {
    width: 17px;
    height: 23px;
}

.broker-para {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
}

.get-symbol-queued {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 600;
    line-height: 125%;
    left: 12px;
}
.round-logo {
    width: 25px;
    height: 25px;
}

.queued-stock-text {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
    right: 20px;
}

.queued-username {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 28px;
}

.queued-you-mimic-txt {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 28px;
    top: 15px;
    opacity: 0.5;
}

.queued-icon {
    width: 31.456px;
    height: 31.456px;
    top: 2rem;
}

.queued-user-img {
    width: 97.087px;
    height: 97.087px;
}

.queud-para {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.order-queued-para {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 500;
    line-height: 24px;
}

.queued-parent-div {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
}

.success-done-btn {
    width: 100%;
    height: 52px;
    color: var(--pf-primary-canary-d-3-ff-5-b, #d3ff5b);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    border-radius: 54px;
    letter-spacing: 0.7px;
    background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    top: 1rem;
}

.success-estimare-digit {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
    margin-right: 13%;
}

.success-estimate-txt {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
    right: 7px;
}

.success-amt-investment-txt {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
    right: 7px;
}

.success-getsymbol {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 600;
    line-height: 125%;
}

.success-round-logo {
    width: 25px;
    height: 25px;
}

.success-stock-txt {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
    right: 10px;
}

.success-username {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 28px;
}

.success-you-mimic-text {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    line-height: 28px;
    top: 15px;
    opacity: 0.5;
}

.success-white-border-logo {
    width: 31.456px;
    height: 31.456px;
    top: 2rem;
}

.success-user-image {
    width: 97.087px;
    height: 97.087px;
}

.success-div {
    width: 100%;
    text-align: center;
}

.order-success-txt {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 500;
    line-height: 24px;
    top: 15px;
}

.success-parent-div {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    margin-left: 10%;
}

.condirm-modal-btn {
    width: 100%;
    height: 52px;
    color: var(--pf-primary-canary-d-3-ff-5-b, #d3ff5b);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    border-radius: 54px;
    letter-spacing: 0.7px;
    background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
}

.confirm-estimate-txt {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
}

.confirm-estimate-digit {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
}

.middle-line {
    height: 48px;
}

.confirm-estimate-cost {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
}

.success-sharedata {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
}

.success-estimate-div {
    width: 100%;
    height: 85px;
    border-radius: 14px;
    background: var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8);
}

.success-robin-txt {
    color: #1f1f1f;
    font-style: normal;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 500;
    line-height: normal;
    margin-left: 5px;
}

.confirm-robin-img {
    width: 17px;
    height: 23px;
}

.confirm-broker-txt {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
}

.brokerage-parent-div {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
}

.confirm-get-symbol {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 600;
    line-height: 125%;
}
.confirm-stock-txt {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
    right: 4px;
}

.confirm-stock-div {
    padding-left: 2rem;
    padding-right: 2rem;
}

.confirm-cancel-txt {
    color: var(--pf-additional-sunset-ff-5445, #ff5445);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 500;
    line-height: 24px;
}

.confirm-txt {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

.confirm-arrow-img {
    width: 24px;
    height: 24px;
    text-align: left;
}

.confirm-parent-div {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Mimicking Notification Modal'] {
    @include devices(largeMobile) {
        width: 100% !important;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Sec Order Success Modal'] {
    @include devices(largeMobile) {
        width: 100% !important;
    }
}
