.verified-trader {
    .verified-trader-link {
        text-decoration: none;
    }

    .img-radius {
        border-radius: 5px;
    }

    .verified-trader-profile-icon {
        width: 100.839px;
        height: 100.837px;
        border-radius: 180px;
    }

    .verified-blue-icon {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 55px;
        top: 65px;
    }

    .verified-trader-username {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-style: normal;
        line-height: normal;
    }
}
