@import 'global_scss/media.scss';

.reviews-container {
    width: 406px;
    @include devices(largeMobile) {
        width: 100%;
        height: auto;
    }

    .reviews-flex {
        width: 406px;
        @include devices(largeMobile) {
            width: 100%;
            height: auto;
        }
    }

    .review-text-container {
        width: 830px;
        @include devices(largeMobile) {
            width: 100%;
        }
    }

    .review-sub-header {
        width: 53px;
        height: 53px;
        border-radius: 0px 287px 287px;
        background-color: var(--pf-secondary-malibu-64-a-2-ff, #64a2ff);
    }
    .review-sub-star-icon {
        width: 26.5px;
        height: 26.5px;
    }

    .review-sub-count {
        color: var(--pf-secondary-malibu-64-a-2-ff, #64a2ff);
        font-size: 28px;
        font-family: Figtree;
        font-weight: 500;
    }

    .review-sub-label {
        color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
        font-size: 12px;
        font-family: Figtree;
        width: 71px;
        height: 17px;
    }

    .view-all-label {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        cursor: pointer;
    }

    .ele-reviews {
        display: flex;
        width: 312px;
        flex-direction: column;
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        line-height: 18px;
        opacity: 0.5;

        @include devices(mediumMobile) {
            width: 100%;
        }
    }

    .user-image {
        width: 39px;
        height: 39px;
    }

    .user-name-sub-review {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 13px;
        font-family: Figtree;
        font-weight: 500;
    }
}
