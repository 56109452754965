@import 'global_scss/media.scss';
* {
    position: relative;
}

.login-dark {
    height: 100%;
    background-size: cover;
    position: relative;
}

.login-dark form {
    max-width: 320px;
    width: 90%;
    padding: 40px;
    border-radius: 4px;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

.login-dark .illustration {
    text-align: center;
    padding: 15px 0 20px;
    font-size: 100px;
    color: #2980ef;
}

.login-dark form .form-control {
    background: none;
    border: none;
    border-bottom: 1px solid #434a52;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: inherit;
}

.login-dark form .forgot {
    display: block;
    text-align: center;
    font-size: 12px;
    color: #6f7a85;
    opacity: 0.9;
    text-decoration: none;
}

.login-dark form .forgot:hover,
.login-dark form .forgot:active {
    opacity: 1;
    text-decoration: none;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    font-size: 30px;
    text-align: center;
}

.flex-item-left {
    background-color: #dae5e3;

    padding: 10px;
    flex: 50%;
}

.flex-item-right {
    padding: 10px;
    flex: 50%;
}

.middle-dot-box {
    position: absolute;
    width: 350.62px;
    height: 370px;
    left: calc(20% - 448.62px / 2 + 52.93px);
    top: 5.81%;
    bottom: -1.23%;

    background: #1f1f1f;
    border-radius: 500.463px 500.463px 500.463px 0px;
    transform: rotate(180deg);
}

.paragraph {
    position: absolute;
    width: 500.62px;
    left: calc(15% - 448.62px / 2 + 52.93px);
    top: 60.81%;
    bottom: -1.23%;
    background-color: #dae5e3;
}

.white-div {
    box-sizing: border-box;
    height: 10rem;
    position: absolute;
    left: 6.94%;
    right: 68.21%;
    top: 45.91%;
    bottom: 1.89%;
    width: 20rem;
    background: #f5f8f8;
    border: 1.37615px solid #dae5e3;
    border-radius: 33.0275px;
}

.bottom-color {
    position: absolute;
    width: 746px;
    left: calc(10% - 448.62px / 2 + 52.93px);
    top: 80.81%;
    bottom: -10.23%;
    background-color: #dae5e3;
    border-bottom-right-radius: 25%;
}

.group {
    position: absolute;
    left: 0.2%;
    right: 70.5%;
    top: 7.22%;
    bottom: 80.4%;
    transform: rotate(180deg);
    width: 20rem;
    height: 31px;
    font: Figtree;
    align-items: Center;
    font-size: 20px;
}

.frame {
    position: absolute;
    bottom: 5.4%;
    transform: rotate(180deg);
}

.celebrate {
    transform: rotate(180deg);
    position: absolute;
    right: 37.5%;
    top: 18.22%;
}

.green-rectengle {
    position: absolute;
    bottom: 82.4%;
    width: 15rem;
    height: 5rem;
}

.green-rectengle-img {
    position: absolute;
    bottom: 84.4%;
    transform: rotate(180deg);
    left: 95%;
}

.green-rectengle-text {
    position: absolute;
    bottom: 90%;
    transform: rotate(180deg);
    left: 60%;
}

.green-rectengle-month {
    position: absolute;
    bottom: 95%;
    transform: rotate(180deg);
    left: 68%;
}

.purple-trangle {
    position: absolute;
    transform: rotate(180deg);
    left: 120px;
    bottom: 310px;
}

.cross-text {
    color: white;
    position: absolute;
    left: 150px;
    bottom: 310px;
}

.loader-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
}

/* ---------------- */

.main-container-login {
    height: auto;
    overflow: hidden;
    .first-block {
        @include devices(1024) {
            width: 100%;
        }
    }
    .form-container {
        @include devices(1024) {
            width: 100%;
        }
    }
}

.register-btn:hover {
    opacity: 0.7;
}
.login-btn:hover {
    opacity: 0.7;
}
.welcome-title {
    font-size: 18px;
    font-family: figtree;
}

.parrot-logo {
    text-align: center;
}

.parrot-logo img {
    width: 50%;
    @include devices(largeMobile) {
        width: 60%;
    }
}

.error_message {
    color: red;
}

.isVisible {
    @include devices(1024) {
        display: none !important;
    }
}

.form-input {
    height: 3.5rem;
    margin-bottom: 12px;
    font-size: 18px;
}
iframe {
    display: none;
}
.password-field {
    position: relative;
    display: flex;
    align-items: center;
}

.password-field.col i {
    position: absolute;
    right: 13px;
    top: 30%;
}

.form-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .col-lg-7.col-md-10 {
        @include devices(largeMobile) {
            width: 80%;
        }
    }
}

.form-container a {
    font-size: 18px;
}

.button-container .login-btn,
.register-btn {
    width: 100%;
    height: 3rem;
    font-size: 18px;
}

.login-btn {
    border: 1px solid #d3ff5b;
    background: #d3ff5b;
    color: #1f1f1f;
}

.register-btn {
    width: 100%;
    border: 1px solid #1f1f1f;
    background: #1f1f1f;
    color: #d3ff5b;
}

.skip-button {
    display: none;
    position: absolute;
    right: 95px;
    bottom: 1rem;
    background: unset;
    border: 0;
    color: rgba(31, 31, 31, 1);
    font-size: 18px;
    font-weight: 500;
    &:hover {
        background: unset;
        border: 0;
        color: rgba(31, 31, 31, 1);
    }
    @include devices(1024) {
        display: block;
    }
    @include devices(largeMobile) {
        bottom: 0.2rem;
        right: 7%;
    }
}
