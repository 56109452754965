.common-color {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
}
.card-detail {
    font-size: 16px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    margin-left: 12px;
    cursor: pointer;
}
.card-icon {
    width: 36px;
    height: 36px;
}

.right-arrow-fa {
    color: #ecf2f1;
    margin-right: 1rem;
}

.left-margin {
    margin-left: 7px;
}
