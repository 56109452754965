@import 'global_scss/media.scss';

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Order Confirmation Modal'] {
    @include devices(largeMobile) {
        width: 95% !important;
    }

    .cost-estimation {
        height: 85px;
        border-radius: 14px;
        background: var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8);
        @include devices(largeMobile) {
            margin: 0px !important;
        }
    }
}
