@import 'global_scss/media.scss';

.return-trade-info {
    height: 116px;
    flex-shrink: 0;
    border-radius: 20px;
    border: 1px solid var(--ffffff, rgba(255, 255, 255, 0.1));
    background-color: rgb(255, 255, 255);
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
    @include devices(mediumTablet) {
        margin-top: 2rem;
    }

    .first-heading-Text {
        font-size: 14px;
        font-family: Figtree;
        color: rgb(31, 31, 31);
    }

    .switch-icon {
        width: 15.6px;
        height: 18px;
        cursor: pointer;
        margin-left: 6px;
    }

    .first-heading-img {
        max-width: 28px;
        height: 33px;
        flex-shrink: 0;
        cursor: pointer;
    }

    .bottom-text {
        font-size: 28px;
        font-family: Figtree;
        font-weight: 500;
        margin-left: 15px;
    }
    .right-border-2px {
        border-right: 2px solid #efefef;
    }
}
