.following-username {
    color: #1f1f1f;
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
}

.btn-block {
    width: 91px;
    height: 30px;
    border-radius: 54px;
    border: 1px solid #1f1f1f;
    color: #1f1f1f;
    font-family: Figtree;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    background: #fff;
    font-size: 10px;
}

.unfollow-btn {
    width: 325px;
    height: 48px;
    border-radius: 95px;
    border: 1px solid #1f1f1f;
    color: #d3ff5b;
    font-family: Figtree;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.5px;
    background: #1f1f1f;
    font-size: 14px;
    margin-left: 15px;
}
