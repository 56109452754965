.buying-power {
    width: 100%;
    height: 67px;
    border-radius: 14px;
    background: rgb(31, 31, 31);
    margin-bottom: 1rem;

    .power-text {
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        opacity: 0.8;
    }
    .power-value {
        color: rgb(211, 255, 91);
        font-size: 20px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 1px;
        white-space: nowrap;
    }
}

.exclamation-buying-power {
    border: 2px solid;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: rgba(31, 31, 31, 1);
}

.textWarn {
    color: rgba(255, 84, 69, 1) !important;
}
.warnBg {
    background-color: #dec1be;
    .power-text {
        color: black;
    }
}

.tooltip-buying-power {
    background-color: white;
    color: #222;
    border-radius: 12px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    .tooltip-inner {
        background-color: white;
        color: black;
        max-width: none;
        text-align: inherit;
        font-size: 12px;
    }

    .tooltip-arrow::before {
        border-top-color: white;
    }
}
