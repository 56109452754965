@import 'global_scss/media.scss';

.analyst-is-subscribe {
    width: 100%;
    height: auto;
    background: rgb(255, 255, 255);
    border-radius: 18px;
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    @include devices(largeMobile) {
        width: 100%;
        height: auto;
    }

    .analyst-sub-heading {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        text-align: left;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }

    .analyst-influencer-card {
        width: 124px;
        height: 145px;
        background: rgb(245, 248, 248);
        border-radius: 15px;
    }

    .influencer-image-from-analyst {
        width: 53px;
        height: 53px;
        border-radius: 50px;
    }

    .analyst-sub-name {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }

    .analyst-right-icon {
        background: rgb(224, 226, 226);
        width: 25px;
        border-radius: 50px;
    }
    .analyst-team-performance {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }

    .grow-analyst-fa {
        width: 17px;
        height: 17px;
    }

    .gorw-icon-analyst {
        width: 31px;
        height: 31px;
    }

    .analyst-win-rate {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 25px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }

    .analyst-win-rate-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        opacity: 0.8;
    }

    .analyst-ytd-block {
        width: 100%;
        height: 72px;
        border-radius: 15px;
        background: rgb(218, 229, 227);
    }

    .analyst-ytd-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 20px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }

    .analyst-ytd-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        opacity: 0.8;
    }

    .switch-icon {
        width: 13px;
        height: 15px;
        margin-left: 6px;
    }

    .analyst-total-trade {
        width: 100%;
        height: 72px;
        border-radius: 15px;
        background: rgb(218, 229, 227);
    }

    .total-trade-sub-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        opacity: 0.8;
    }
}
