@import 'global_scss/media.scss';

.analytics-board {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }
    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }
    .middle-container {
        top: -3rem;
        @include devices(tablet) {
            top: 1rem;
        }
    }

    .return-YTD,
    .discovered-followers {
        width: 100%;
        height: 116px;
        flex-shrink: 0;
        border-radius: 20px;
        border: 1px solid var(--ffffff, rgba(255, 255, 255, 0.1));
        background-color: rgb(255, 255, 255);
        padding: 8px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
        @include devices(tablet) {
            margin-top: 0.5rem;
        }
    }

    .discovered-followers,
    .subscriber-follower-container {
        @include devices(tablet) {
            margin-top: 1.5rem !important;
        }
    }

    .white-graph {
        width: 100%;
        height: 274px;

        @include devices(tablet) {
            height: auto;
        }
    }

    .statistics-wrap {
        @include devices(largeMobile) {
            flex-wrap: wrap;
        }
    }

    .analytics-double-bar-graph {
        background: rgba(255, 255, 255, 1);
        padding: 1rem;
        position: relative;
        border-radius: 20px;
        .bar-double-graph {
            height: 200px;
        }
        ul.recharts-default-legend {
            position: absolute;
            right: 93px;
            top: -2rem;
        }
        span.recharts-legend-item-text {
            color: rgba(31, 31, 31, 1);
            font-size: 12px;
        }
    }

    .churn-rate-container {
        width: 100%;
        height: 70px;
        border-radius: 18px;
        background: rgb(218, 229, 227);

        .churn-rate-label {
            color: rgb(31, 31, 31);
            font-size: 18px;
            font-family: Figtree;
            font-weight: 400;
            opacity: 0.5;
            font-style: normal;
            line-height: normal;
        }

        .churn-rate-count {
            color: rgb(31, 31, 31);
            font-size: 25px;
            font-family: Figtree;
            font-weight: 500;
            font-style: normal;
            line-height: normal;
        }
    }
}

.arrowImg {
    width: 20px;
    cursor: pointer;
}

.arrowAnalyst {
    right: 5rem;
    top: 10px;
}

.invite-button {
    width: 250px;
    height: 46px;
    text-decoration: none;
    background-color: #1f1f1f;
    border-radius: 54px;
    color: #fff;
    border: 1px solid #1f1f1f;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.7px;
    cursor: pointer;
}

.avg-gain-div {
    width: 100%;
    height: 91px;
    border-radius: 18px;
    border: 1px solid #ecf2f1;
    background: #fff;
}

.avg-gain-per {
    color: #1f1f1f;
    font-size: 18px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
}

.avg-gain-text {
    color: #1f1f1f;
    font-size: 12px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    opacity: 0.5;
}

.stroke-line {
    height: 40px;
    opacity: 0.10000000149011612;
    color: #1f1f1f;
}

.white_symbol_img {
    width: 41px;
    height: 46px;
    color: #ffffff;
}

.outline_img {
    width: 15.6px;
    height: 18px;
    margin-left: 6px;
}

.union_img {
    max-width: 28px;
    height: 33px;
    flex-shrink: 0;
}

.trending-down {
    width: 14px;
    height: 14px;
}

.fire_img {
    max-width: 28px;
    height: 33px;
    flex-shrink: 0;
}

.smile_img {
    max-width: 32px;
    height: 22px;
    flex-shrink: 0;
}

.tiktok {
    max-width: 32px;
    height: 22px;
    flex-shrink: 0;
}

.analytics {
    width: 23px;
    height: 23px;
}

.analytics_followers {
    width: 23px;
    height: 23px;
}

.arrow-up-right2 {
    width: 16px;
    height: 16px;
    margin-left: 3rem;
}

.static-title-with-line {
    width: 100%;
    height: 19px;
}

.trending_img {
    width: 14px;
    height: 14px;
}

.calender_icon {
    width: 41px;
    height: 46px;
    color: #ffffff;
}

.side-notification-component {
    height: 849px;
    border-radius: 25px 0px 0px 25px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    bottom: 4rem;
    box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.06);
    padding-top: 2rem;
    padding-left: 2.2rem;
}

.subscriber-main {
    .subscriber-main-user-image {
        width: 48px;
        height: 48px;
        border-radius: 48px;
    }

    .subscriber-main-orange-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2rem;
        left: 2px;
    }

    .subscriber-main-username {
        color: rgb(31, 31, 31);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
    }

    .subscriber-main-subscriptionId {
        color: rgb(143, 143, 143);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
    }
}
