@import 'global_scss/media.scss';

.normal-user-follower-card {
    width: 100%;
    height: 103px;
    border-radius: 20px;
    background-color: #e9ffad;
    margin-left: 3px;

    @include devices(largeMobile) {
        margin-bottom: 1rem;
    }

    .client-gallery-images {
        width: 54px;
        height: 54px;
    }

    .normal-user-following-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 35px;
        font-family: Figtree;
        line-height: normal;
        font-style: normal;
        font-weight: 500;
        text-align: left;
    }

    .normal-user-following-label {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 14px;
        font-family: Figtree;
        line-height: normal;
        font-style: normal;
        font-weight: 400;
    }

    .circles-gallery {
        position: relative;
        margin-left: calc(66px * 0.5);
        > * {
            position: relative;
            display: inline-block;
            margin-left: calc(66px * -0.5);
            height: 100%;
            border: solid 2px #e9ffad;
            border-radius: 50%;
            z-index: 0;
        }
    }

    .normal-user-right-icon {
        width: 38px;
        height: 38px;
        border-radius: 48px;
        background-color: var(--pf-primary-white-ffffff, #fff);
        margin-top: 8px;

        .subscription-arrow-img {
            width: 16px;
            height: 16px;
            cursor: pointer;
        }
    }
}
