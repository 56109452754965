@import 'global_scss/media.scss';

.influencer-dashboard-pop {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }
    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }

    .broker-graph {
        @include devices(largeMobile) {
            flex-direction: column-reverse;
        }

        .dropdown-children-container.show.dropdown {
            width: 100%;
        }
        button.dropdown-toggle.btn.btn-primary {
            padding: 0;
            width: 100%;
        }

        .dropdown-item.active,
        .dropdown-item:active {
            color: unset;
            text-decoration: none;
            background-color: unset;
        }

        .dropdown-menu.show {
            width: 90%;
            margin: 1rem;
            margin-top: 0;
            margin-bottom: 0rem;
        }
    }

    .all-brokers {
        background: rgb(31, 31, 31);
        border-radius: 20px 20px 0px 0px;
        width: 98%;
        height: 82px;
        margin-right: 1rem;

        @include devices(769) {
            &::before {
                content: '';
                width: 32px;
                height: 28px;
                border: 12px solid #1f1f1f;
                position: absolute;
                bottom: -12px;
                border-top: 0;
            }

            &::before {
                border-right: 0;
                border-radius: 0 0 0 44px;
                right: -20px;
            }
        }

        @include devices(largeMobile) {
            width: 100%;
            margin-right: 0rem;
        }
    }

    .buying-power {
        width: 100%;
        height: 67px;
        border-radius: 14px;
        background: rgb(31, 31, 31);
        margin-bottom: 1rem;

        .power-text {
            color: rgb(255, 255, 255);
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            opacity: 0.8;
        }
        .power-value {
            color: rgb(211, 255, 91);
            font-size: 20px;
            font-family: Figtree;
            font-weight: 600;
            letter-spacing: 1px;
            white-space: nowrap;
        }
    }

    .graph-value-container {
        width: 100%;
        border-radius: 0px 20px 20px;
        background: rgb(31, 31, 31);
        margin-top: -1px;
        @include devices(largeMobile) {
            border-radius: 0px 0px 20px 20px;
        }

        .graph-button-container {
            width: 40%;
            margin: auto;
            border-radius: 50px;
            padding: 5px;
            background-color: #3a3a3a;
            align-items: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
            position: absolute;
            right: 0;
            left: 0;
            bottom: 9%;
            margin: auto;
            z-index: 0.9;
            @include devices(largeMobile) {
                width: 100%;
                position: unset;
            }

            button {
                background-color: transparent;
                border-color: transparent;
                border-radius: 50px;
                padding: 0 15px;
                height: 33px;
                &:focus {
                    color: #3a3a3a;
                    border-color: rgb(211, 255, 91);
                    background-color: rgb(211, 255, 91);
                }
            }
            .activeEarning {
                color: #3a3a3a;
                border-color: rgb(211, 255, 91);
                background-color: rgb(211, 255, 91);
            }
        }
    }

    .analytics-overview {
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }
    .analytics-container {
        padding-right: 1rem;
        .subscribed-following {
            border-radius: 20px;
            background: #dae5e3;
            margin: 5px;
        }
        @include devices(largeMobile) {
            padding-right: 0rem;
            .directionFlex {
                flex-wrap: wrap;
            }
        }
    }

    .graph-container {
        margin-left: 10px;
        @include devices(largeMobile) {
            margin-left: 0;
            margin-top: 1rem;
        }
    }

    .spotlight {
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }

    .exclamation-buying-power {
        border: 2px solid;
        border-radius: 50px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        color: rgba(31, 31, 31, 1);
    }

    .textWarn {
        color: rgba(255, 84, 69, 1) !important;
    }
    .warnBg {
        background-color: #dec1be;
        .power-text {
            color: black;
        }
    }

    .graph-button-container {
        width: 90%;
        margin: auto;
        border-radius: 50px;
        padding: 5px;
        background-color: #3a3a3a;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        button {
            background-color: transparent;
            border-color: transparent;
            border-radius: 50px;
            padding: 0 15px;
            height: 33px;
        }

        .activeEarning {
            color: #3a3a3a;
            border-color: rgb(211, 255, 91);
            background-color: rgb(211, 255, 91);
        }
    }

    .broker-dropdown {
        .down-arrow {
            width: 36px;
            height: 36px;
            border-radius: 8px;
            border: 1px solid rgb(79 71 71);
            background: rgb(31, 31, 31);
            i {
                opacity: 0.5;
                color: rgb(255, 255, 255);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .broker-dropdown-item {
        border-bottom: 1px solid rgb(225 225 225);

        span {
            img {
                width: 50px;
                height: 50px;
            }
        }
    }
}

.tooltip-buying-power {
    background-color: white;
    color: #222;
    border-radius: 12px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

    .tooltip-inner {
        background-color: white;
        color: black;
        max-width: none;
        text-align: inherit;
        font-size: 12px;
    }

    .tooltip-arrow::before {
        border-top-color: white;
    }
}

.invite-frnd-text {
    color: #1f1f1f;
    font-size: 25px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
}

.copy-code-text {
    color: #1f1f1f;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: 20.136px;
    opacity: 0.5;
}

.greenleaf {
    width: 119px;
    height: 119px;
}

.leaf-text {
    color: #1f1f1f;
    font-size: 40px;
    font-family: Figtree;
    font-weight: 600;
    bottom: 70px;
    font-style: normal;
    line-height: 20.136px;
}

.input-button-div {
    width: 270px;
    height: 227px;
    border-radius: 20px;
    right: 5px;
}

.input-button-sec-div {
    width: 317px;
    height: 45px;
    border-radius: 64px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 12px 40px 0px rgba(31, 31, 31, 0.08);
}

.copy-code-btn {
    width: 80px;
    color: #d3ff5b;
    background-color: #1f1f1f;
    border-radius: 40px;
    height: 25px;
    font-style: normal;
    font-size: 11px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    gap: 8px;
    border: none;
}

.or-text {
    color: #1f1f1f;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: 23px;
    opacity: 0.5;
}

.manange-icons {
    width: 252px;
    height: 48px;
}

.SideNotificationComponent {
    height: 849px;
    border-radius: 25px 0px 0px 25px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    bottom: 4rem;
    box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.06);
    padding-top: 2rem;
    padding-left: 2.2rem;
}

.influencer-follower-page {
    .influencer-follower-user {
        width: 48px;
        height: 48px;
        border-radius: 48px;
    }

    .influencer-user-orange {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2rem;
        left: 2px;
    }

    .influencer-left-margin {
        margin-left: 10px;
    }

    .influencer-follower-user-name {
        color: rgb(31, 31, 31);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
    }
}
