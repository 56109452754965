.top-holding-main {
    .holding-parent-div {
        width: 100%;
        height: 250px;
        border-radius: 18px;
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        background-color: var(--pf-primary-white-ffffff, #fff);
    }

    .trade-logourl {
        width: 50px;
        height: 50px;
        margin-left: 7px;
    }

    .inflenecer-holding-heading {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 19.2px;
        text-align: center;
    }
    .trade-symbol-holdings {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        padding-left: 10px;
    }
    .trade-detail-holding {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        opacity: 0.5;
        display: flex;
        flex-wrap: nowrap;
        padding-left: 10px;
    }

    .type-text-holding {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
        margin-right: 10px;
    }
}

.padding-left-10px {
    padding-left: 10px;
}

.color-green {
    color: #71c901;
}
