.analytics-mimicked-graph {
    width: 100%;
    border: 20px;
    background: rgb(255, 255, 255);
    border-radius: 20px;

    .mimic_img {
        width: 53px;
        height: 53px;
    }

    .analytics-graph {
        height: 330px;
        g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis {
            display: none;
        }
        .recharts-surface {
            overflow: visible;
        }
    }

    .left-margin-mimic-graph {
        margin-left: 10px;
    }

    .mimic-graph-count {
        color: rgb(31, 31, 31);
        font-size: 25px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }

    .mimic-month-show {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        margin-right: 5px;
        font-style: normal;
        line-height: normal;
        opacity: 0.5;
    }

    .mimic-graph-select {
        width: 95px;
        height: 32px;
        border-radius: 8px;
        border: 1px solid rgb(143, 143, 143);
        background-color: rgb(255, 255, 255);
        color: rgb(255, 255, 255);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
    }

    .mimic-main-selector {
        background-color: var(--pf-primary-black-1-f-1-f-1-f, #fff);
        color: var(--pf-primary-canary-d-3-ff-5-b, #1f1f1f);
        border: none;
        margin-left: 10px;
    }
}
