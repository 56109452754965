.confirm-analyst-btn {
    width: 180px;
    height: 46px;
    text-decoration: none;
    background-color: var(--pf-primary-canary-d-3-ff-5-b, #d3ff5b);
    border-radius: 54px;
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);

    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.7px;
    cursor: pointer;
    border: none;
}
