@import 'global_scss/media.scss';

.payment-success {
    width: 450px;
    border-radius: 25px 25px 0px 0px;
    .back-icon {
        position: absolute;
        left: 2rem;
    }
    .success-text {
        color: rgb(113, 201, 1);
        font-style: normal;
        font-size: 20px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
    }

    .congrats-text {
        color: rgb(31, 31, 31);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
    }
    .influencer-image {
        width: 100px;
        height: 100px;
    }
    .orange-icon {
        width: 33.055px;
        height: 33.055px;
        position: absolute;
        top: 70px;
        margin-right: 40px;
    }
    .subscribed-text {
        color: rgb(31, 31, 31);
        font-style: normal;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        opacity: 0.5;
    }

    .user-name {
        color: rgb(31, 31, 31);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
    }
    .complete-btn {
        width: 280px;
        color: rgb(192, 255, 2);
        background-color: rgb(31, 31, 31);
        border-radius: 54px;
        height: 52px;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='payment success'] {
    @include devices(largeMobile) {
        .payment-success {
            width: 340px;
            border-radius: 25px 25px 0px 0px;
            margin-top: -1.5rem;
        }
    }
}
