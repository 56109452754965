@import 'global_scss/media.scss';

.order-info {
    height: 78px;
    border-radius: 18px;
    background: rgb(255, 255, 255);
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    @include devices(largeMobile) {
        height: 138px;
    }

    .order-info-contain {
        @include devices(largeMobile) {
            flex-wrap: wrap;
        }
    }

    .market-sell {
        left: 70px;
        @include devices(largeMobile) {
            left: 0px;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    .submitted {
        left: 40px;
        @include devices(largeMobile) {
            left: 0px;
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    .pending-order-logo {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: rgb(31, 31, 31);
    }

    .pending-order-margin-left {
        margin-left: 10px;
    }

    .pending-order-symbol {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 125%;
        font-style: normal;
    }

    .entered-qty-top {
        top: 4px;
    }

    .entered-qty-label {
        color: rgb(143, 143, 143);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
    }

    .qty-separator {
        width: 4px;
        height: 5px;
        padding-left: 3px;
        opacity: 0.5;
        color: rgb(143, 143, 143);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
    }

    .qty-amount {
        color: rgb(31, 31, 31);
        font-size: 13px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
        margin-left: 5px;
    }

    .order-type-label {
        color: rgb(143, 143, 143);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
    }

    .order-type-value {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
        padding-top: 2px;
        text-transform: uppercase;
    }

    .pending-order-status {
        color: rgb(255, 121, 46);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
    }

    .pending-order-cancel-btn {
        width: 112px;
        color: rgb(255, 84, 69);
        background-color: rgb(255, 255, 255);
        border-radius: 54px;
        height: 28px;
        border: 1px solid rgb(255, 84, 69);
        text-transform: uppercase;
        font-style: normal;
        font-size: 10px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        top: 5px;
    }
}
