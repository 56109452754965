.influencer-information-popup {
    height: 171px;
    border-radius: 25px 25px 0px 0px;
    .middle-section {
        width: 330px;
    }
    .user-image {
        width: 48px;
        height: 48px;
        border-radius: 48px;
    }
    .orange-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2rem;
        left: 2px;
    }
    .influencer-name {
        color: rgb(31, 31, 31);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
    }

    .block-button {
        width: 91px;
        height: 30px;
        border-radius: 54px;
        border: 1px solid rgb(31, 31, 31);
        color: rgb(31, 31, 31);
        font-family: Figtree;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        background: rgb(255, 255, 255);
        font-size: 10px;
    }

    .un-follow-btn {
        width: 325px;
        height: 48px;
        border-radius: 95px;
        border: 1px solid rgb(31, 31, 31);
        color: rgb(211, 255, 91);
        font-family: Figtree;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        background: rgb(31, 31, 31);
        font-size: 14px;
        margin-left: 15px;
    }
    .name-block {
        margin-left: 15px;
        align-items: baseline;
    }
}
