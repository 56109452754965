@import 'global_scss/media.scss';

.buy-stocks {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    .robesHood {
        margin-left: 5px;
        width: 100%;
    }

    .robesHood-container {
        width: 100%;
        height: 70px;
        border-radius: 14px;
        background: var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8);
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Buy Stocks Modal'] {
    @include devices(largeMobile) {
        width: 95% !important;
        .robesHood {
            margin-left: 0px;
        }
        .robesHood-container {
            margin: 0 !important;
        }
    }
}

.buy_stock_down_arrow {
    width: 8px;
    height: 4px;
    margin-right: 5%;
    z-index: 1;
}

.buy_stock_down_arrow_brokerage {
    width: 8px;
    height: 4px;
    margin-right: 5%;
    z-index: 1;
    margin-top: 1rem;
}

.buy-stock-unit-dropdown {
    border: none;
    background-color: #f5f8f8;
    // -webkit-appearance: none; /* Remove default arrow on Chrome/Safari */
    // -moz-appearance: none; /* Remove default arrow on Firefox */
    // appearance: none;
}

.buy_stock_down_arrow_brokerage {
    width: 15px;
    height: 10px;
    margin-right: 5%;
    z-index: 1;
    margin-top: 1rem;
}
