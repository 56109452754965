.custom-file-input {
  display: none;
}

.custom-file-label {
  display: inline-block;
  background-color: #F5F8F8;
  border: 1px solid #ccc;
  border-style: dashed;
  padding: 20px;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  font-weight: normal;
  color: #555;
  /* position: absolute;
  bottom: 25px;
  left: 25rem;
  z-index: 1; */
}

.custom-file-label:hover {
  background-color: #e5e5e5;
}

.custom-file-label:active {
  background-color: #ddd;
}

.custom-file-input:focus + .custom-file-label {
  outline: none;
  border-color: #66afe9;
  box-shadow: 0 0 6px #66afe9;
}

.custom-file-input:focus + .custom-file-label::after {
  content: ''; /* Empty string to remove the "+ N" text */
}
