@import 'global_scss/media.scss';

.star > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

.influencer-review {
    .influencer-container {
        min-height: 245px;
        background: #1f1f1f;
        @include devices(mediumTablet) {
            min-height: 263px;
        }
        @include devices(largeMobile) {
            min-height: 320px;
            .influencer-name {
                top: 4rem;
            }
            .search-bar {
                margin-top: 6rem;
            }
        }
    }

    .review-container {
        border-radius: 18px;
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        background: rgb(255, 255, 255);
        bottom: 4rem;

        @include devices(largeMobile) {
            bottom: -2rem;
        }
    }

    .star-container {
        width: 44px;
        height: 44px;
        border-radius: 0px 287px 287px;
        background: rgb(100, 162, 255);
    }

    .star-icon {
        width: 22px;
        height: 22px;
    }

    .review-margin-left {
        margin-left: 10px;
    }

    .review-count {
        font-size: 18px;
        font-family: Figtree;
        color: rgb(100, 162, 255);
        font-style: normal;
        line-height: normal;
    }

    .all-review-heading {
        font-size: 18px;
        font-family: Figtree;
        font-style: normal;
        line-height: normal;
        bottom: 15px;
    }
    .influencer-image-review {
        width: 39px;
        height: 39px;
        border-radius: 50%;
    }

    .review-user-name {
        font-size: 15px;
        font-family: Figtree;
        color: rgb(31, 31, 31);
        font-style: normal;
        line-height: normal;
        font-weight: 500;
    }

    .review-desc {
        font-size: 13px;
        font-family: Figtree;
        color: rgb(31, 31, 31);
        font-style: normal;
        line-height: 18px;
        font-weight: 400;
        opacity: 0.5;
        bottom: 10px;
    }
    .review-date {
        font-size: 15px;
        font-family: Figtree;
        color: rgb(31, 31, 31);
        font-style: normal;
        line-height: normal;
        font-weight: 500;
    }

    .subscribe-section-review {
        width: 100%;
        height: 59px;
        border-radius: 15px;
        background-color: rgb(245, 248, 248);
        border: 1px solid var(--pf-secondary-geyser-dae-5-e-3, #dae5e3);
    }
    .input-review {
        width: 100%;
        height: 57px;
        border-radius: 15px;
        background-color: rgb(245, 248, 248);
        border: none;
    }

    .submit-review-button {
        width: 38px;
        height: 38px;
        background: rgb(31, 31, 31);
        border-radius: 10px;
        right: 4px;
    }

    .left-arrow {
        width: 18px;
        height: 18px;
        margin-left: 5px;
        cursor: pointer;
    }
}
