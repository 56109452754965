.become-analyst-setting {
    .left-margin {
        margin-left: 15px;
    }
    .analyst-user-image {
        width: 48px;
        height: 48px;
        border-radius: 48px;
    }
    .orange-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2rem;
        left: 2px;
    }

    .influencerfullname {
        color: #1f1f1f;
        font-size: 15px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
    }

    .influencerUsername {
        color: #8f8f8f;
        font-size: 10px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
        bottom: 13px;
    }

    .accept-btn {
        width: 100px;
        color: #1f1f1f;
        background-color: #d3ff5b;
        border-radius: 54px;
        height: 28px;
        border: 1px solid #d3ff5b;
        text-transform: uppercase;
        font-style: normal;
        font-size: 10px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        top: 5px;
        margin-right: 10px;
    }

    .decline-btn {
        width: 100px;
        color: #1f1f1f;
        background-color: #fff;
        border-radius: 54px;
        height: 28px;
        border: 1px solid #1f1f1f;
        text-transform: uppercase;
        font-style: normal;
        font-size: 10px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.5px;
        top: 5px;
    }
}

.sidebar-fa-icon {
    font-size: 24px;
    color: #8f8f8f;
}
