@import 'global_scss/media.scss';
body {
    background-color: #f9f9fa;
}

.padding {
    padding: 3rem !important;
}

.user-card-full {
    overflow: hidden;
}

.card {
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px;
}

.m-r-0 {
    margin-right: 0px;
}

.m-l-0 {
    margin-left: 0px;
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px;
}

.bg-c-lite-green {
    background: -webkit-gradient(linear, left top, right top, from(green), to(green));
    /* background: linear-gradient(to right, #ee5a6f, #f29263); */
    background-color: linear-gradient(to right, green);
}

.user-profile {
    padding: 20px 0;
}

.card-block {
    padding: 1.25rem;
}

.m-b-25 {
    margin-bottom: 25px;
}

.img-radius {
    border-radius: 5px;
}

h6 {
    font-size: 14px;
}

.card .card-block p {
    line-height: 25px;
}

.change-btn {
    p {
        font-size: 18px;
        font-weight: 600;
        color: #3675ff;
        cursor: pointer;
    }
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px;
    }
}

.card-block {
    padding: 1.25rem;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.m-b-20 {
    margin-bottom: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.card .card-block p {
    line-height: 25px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.text-muted {
    color: #919aa3 !important;
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0;
}

.f-w-600 {
    font-weight: 600;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-40 {
    margin-top: 20px;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-t-40 {
    margin-top: 20px;
}

.user-card-full .social-link li {
    display: inline-block;
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.image-container {
    display: flex;
    overflow-x: auto;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.image-item {
    flex: 0 0 200px;
    margin-right: 10px;
    margin-bottom: 100px;
}

.image-item:last-child {
    margin-right: 0;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown-option {
    color: black;
    padding: 8px 12px;
    text-decoration: none;
    display: block;
}
/* 
  @media only screen 
  and (min-device-width: 414px)  
  and (max-device-width: 896px) 
  
  and (-webkit-min-device-pixel-ratio: 2)
  and (-webkit-max-device-pixel-ratio: 2) {
    .col-ipad-6 {
        width: 100%; 
    }
} */

.checkbox-container {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #f5f8f8;
    border: none;
    border-radius: 3px;
    border: 2px solid #fff;
}

.checkbox-container:hover .checkmark {
    border-color: green;
}

.checkbox-container input:checked ~ .checkmark {
    /* color:green; */
    background-color: green;
    border-color: transparent;
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

/* Hide the default checkbox */
input[type='checkbox'] {
    display: none;
}

/* Style the label to represent the checkbox */
label {
    display: inline-block;
    position: relative;
    padding-left: 25px; /* Adjust this value based on your design */
    cursor: pointer;
}

/* Style the checkbox tick */
label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px; /* Adjust this value based on your design */
    height: 18px; /* Adjust this value based on your design */
    border: 2px solid #000; /* Set the color of the tick */
}

/* Style the checkbox tick when checked */
input[type='checkbox']:checked + label::before {
    background-color: #000; /* Set the color of the tick */
}

.influencer {
    .influencer-container {
        min-height: 245px;
        background: #1f1f1f;
        @include devices(mediumTablet) {
            min-height: 263px;
        }
        @include devices(largeMobile) {
            min-height: 320px;
            .influencer-name {
                top: 4rem;
            }
            .search-bar {
                margin-top: 6rem;
            }
        }
    }

    .middle-container {
        position: absolute;
        top: 12rem;
        @include devices(mediumTablet) {
            top: 0;
            position: inherit;
        }
    }

    .followers-info {
        height: 116px;
        flex-shrink: 0;
        border-radius: 20px;
        border: 1px solid var(--ffffff, rgba(255, 255, 255, 0.1));
        background-color: rgb(255, 255, 255);
        padding: 8px 8px 8px 18px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 9px 20px 0px;
        @include devices(mediumTablet) {
            margin-top: 2rem;
        }
    }

    @include devices(mediumTablet) {
        .media-center-flex {
            justify-content: center;
            width: 100%;
            @include devices(largeMobile) {
                justify-content: space-between;
                .media-direction {
                    flex-direction: column;
                }
            }
        }
    }

    .recent-trades {
        color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        @include devices(largeMobile) {
            white-space: nowrap;
        }
    }

    .verified-text {
        color: var(--pf-secondary-malibu-64-a-2-ff, #64a2ff);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        @include devices(largeMobile) {
            display: flex;
            align-items: center;
        }
    }

    .view-all-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        cursor: pointer;
        @include devices(largeMobile) {
            white-space: nowrap;
        }
    }

    .statistics-section {
        width: 400px;
        height: 100px;

        @include devices(largeMobile) {
            width: 100%;
            height: auto;
        }
    }

    .analyst-section {
        width: 406px;
        height: 223px;
        background: rgb(255, 255, 255);
        border-radius: 18px;
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        @include devices(largeMobile) {
            width: 100%;
            height: auto;
        }
    }

    .influencer-analytics-img {
        width: 100%;
        height: auto;
        @include devices(largeMobile) {
            width: 100%;
            height: auto;
        }
    }

    .win-rate-section {
        width: 406px;
        height: 121px;
        border-radius: 18px;
        background-color: var(--pf-additional-calamansi-e-9-ffad, #e9ffad);
        @include devices(largeMobile) {
            width: 100%;
            height: auto;
        }
    }

    .breaking-trade-info {
        @include devices(largeMobile) {
            flex-wrap: wrap;
            justify-content: center;
            .media-bottom-margin {
                margin-bottom: 1rem;
            }
        }
    }

    .top-holdings {
        width: 400px;

        @include devices(mediumTablet) {
            text-align: center;
            margin: auto;
        }
        @include devices(largeMobile) {
            width: 100%;
        }
    }
    .team-performance {
        width: 100%;
        height: 60px;
        background: rgb(218, 229, 227);
        border-radius: 12px;

        @include devices(largeMobile) {
            width: 100%;
        }
    }

    .person-name-info {
        width: 100%;
        height: 87px;
        border-radius: 18px;
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        background-color: var(--pf-primary-white-ffffff, #fff);
        @include devices(largeMobile) {
            width: 100%;
            height: auto;
        }
    }

    .top-holding-blur {
        width: 100%;
        @include devices(mediumTablet) {
            margin: auto;
        }

        @include devices(largeMobile) {
            width: 100%;
        }
    }
}

.avgtrade-text {
    color: '#1F1F1F';
}

.recent-trade-logo {
    width: 35px;
    height: 35px;
}

.shares-number {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 12px;
    font-family: Figtree;
}

.influencer-header {
    width: 2000px;
    min-height: 245px;
    background: #1f1f1f;
}

.modal-import-img {
    width: 12.76px;
    height: 12.76px;
    cursor: pointer;
}

.success-parent-div {
    width: 325px;
    height: 523px;
    border-radius: 30px;
}

.influencer-img {
    width: 100px;
    height: 100px;
}

.done-button {
    width: 275px;
    height: 46px;
    color: #e9ffad;
    border-radius: 54px;
    background-color: #1f1f1f;
}

.side-notification {
    height: 849px;
    border-radius: 25px 0px 0px 25px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    bottom: 4rem;
    box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.06);
    padding-top: 2rem;
    padding-left: 2.2rem;
    overflow-y: auto;

    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #1f1f1f;
        border-radius: 20px;
        border: 3px solid transparent;
    }
}

.fetchrecenttrade-parent-div {
    filter: blur(6px);
}

.fetchrecenttrade-div {
    width: 100%;
    height: 78px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    border-radius: 18px;
}

.fetchRecentTrades-div {
    width: 100%;
    height: 78px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    border-radius: 18px;
}

.fetchRecentTrades-logo {
    width: 15.867px;
    height: 18.133px;
}

.inflenecer-holding-heading-change {
    width: 69px;
    height: 20px;
    cursor: pointer;
}

.update-dispaly-heading {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 18px;
    font-family: Figtree;
    font-weight: 500;
    line-height: 21.6px;
}

.update-dispaly-heading-cancel {
    color: #ff5445;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 600;
    line-height: 21.6px;
    cursor: pointer;
}

.statistic-view-all-click {
    color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
    font-size: 16px;
    font-family: Figtree;
    font-weight: 500;
    cursor: pointer;
}

.ReactModalPortal {
    .ReactModal__Overlay {
        background-color: rgba(31, 31, 31, 0.8) !important;
        .content {
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            width: 400px;
            height: auto;
            border-radius: 25px;
            background-color: var(--pf-primary-white-ffffff, #fff);
            padding: 0px;
            border: none;
            .container {
                .item {
                    cursor: pointer;
                    padding: 0px 2rem 0px 1rem;
                    &:hover {
                        background-color: #00000009;
                    }
                    p {
                        font-size: 18px;
                        padding: 15px;
                        margin: 0px;
                    }
                }
            }
            @media (max-width: 600px) {
                width: 320px !important;
            }
            &:focus {
                outline: none;
            }
        }
    }
}

.no-public-trade {
    color: #1f1f1f;
    font-size: 14px;
    font-family: Figtree;
}
.pending-payment-text {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 20px;
    font-family: Figtree;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.pending-payment-description {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-style: normal;
    font-size: 16px;
    font-family: Figtree;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    opacity: 0.5;
}

.go-to-webpage-btn {
    width: 200px;
    color: rgb(211, 255, 91);
    background-color: rgb(31, 31, 31);
    border-radius: 54px;
    height: 52px;
    border: none;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    letter-spacing: 0.7px;
    font-style: normal;
}
