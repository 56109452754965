@import 'global_scss/media.scss';

.mobile-nav {
    background-color: #1f1f1f !important;
    margin-bottom: 0;

    .userImage {
        border-radius: 85px 0px 85px 85px;
        cursor: pointer;
    }

    button.navbar-toggler.collapsed {
        background: rgb(211, 255, 91);
        &:focus {
            border: unset;
            outline: unset;
        }
    }
}

.mobile-menu-header {
    .person-image {
        width: 53px;
    }
}

div#offcanvasNavbar-expand-false {
    width: 12%;
    background-color: #1f1f1f;

    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 4;
        top: 0;
        left: unset;
        background-color: #111;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 0;
        right: 0;
    }

    .mobile-hidden {
        @include devices(tablet) {
            margin-top: 1rem;
        }
    }
}

.navbar-container {
    position: relative;
    .navbar-notification {
        position: absolute;
        right: 4rem;
        i {
            color: rgb(211, 255, 91);
        }
    }
}

.bottom-down-content {
    padding-bottom: 30px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
    @include devices(tablet) {
        position: absolute;
    }
    @include devices(1366) {
        position: unset;
    }
    @include devices(largeMobile) {
        position: unset;
    }
}

.small-logo {
    border-radius: 85px 0px 85px 85px;
    cursor: pointer;
    @include devices(tablet) {
        margin-top: 1rem;
    }
}

.portfolio-icon {
    width: 26px;
    height: 26px;
}

.portfolio-icon-active {
    width: 26px;
    height: 26px;
}

.setting-icon {
    width: 26px;
    height: 26px;
}
