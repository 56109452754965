@import 'global_scss/media.scss';

.stock-investment {
    .stock-header-text {
        color: rgb(31, 31, 31);
        font-style: normal;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
    }
    .stock-middle-header-text {
        color: rgb(31, 31, 31);
        font-style: normal;
        font-size: 30px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
        margin-left: 10px;
    }
    .stock-description {
        color: rgb(31, 31, 31);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 400;
        line-height: 24px;
    }
    .complete-btn {
        width: 280px;
        color: rgb(192, 255, 2);
        background-color: rgb(31, 31, 31);
        border-radius: 54px;
        height: 52px;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='stock investments'] {
    @include devices(mediumTablet) {
        width: 407px !important;
        height: auto !important;
        .d-flex.justify-content-center.px-2.mt-4 {
            margin-top: 0rem !important;
        }
    }

    @include devices(largeMobile) {
        width: 330px !important;
    }
}
