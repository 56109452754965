.analyst-subs-img {
    width: 48px;
    height: 48px;
    border-radius: 48px;
}

.analyst-subs {
    color: #1f1f1f;
    font-size: 15px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
}
