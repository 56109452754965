.top-holding-sub-container {
    .sub-top-holding-card {
        width: 100%;
        height: 275;
        border-radius: 18px;
        border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
        background-color: var(--pf-primary-white-ffffff, #fff);
    }

    .sub-stock-symbol {
        margin-left: 20px;

        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        padding-left: 5px;
    }

    .top-holding-sub-name {
        // margin-left: 10px;
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        opacity: 0.5;
        display: flex;
        flex-wrap: nowrap;
    }
    .top-holding-padding-right {
        padding-right: 10px;
    }
    .fa-angle-up-color {
        color: #71c901;
    }

    .fa-angle-down-color {
        color: red;
    }

    .top-holding-children {
        position: absolute;
        bottom: 70px;
        height: 32px;
    }

    // .top-holding-logo-bg-black {
    //     width: 36px;
    //     height: 36px;
    //     border-radius: 50%;
    //     align-items: center;
    //     justify-content: center;
    //     background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    //     // margin: auto;
    // }
    // .top-holding-sub-logo {
    //     width: 20px;
    //     height: 20px;
    //     margin: auto;
    //     align-self: center;
    //     text-align: center;

    //     // margin-left: 7px;
    // }
    .top-holding-logo-bg-black {
        display: flex;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        margin-left: 20px;
        margin-bottom: 10px;
    }

    .top-holding-sub-logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .holding-sub-amount {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
        // margin-right: 10px;
    }
}
