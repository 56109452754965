@import 'global_scss/media.scss';
.average-section-statictis {
    width: 406px;
    height: 91px;
    border-radius: 18px;
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    background-color: var(--pf-primary-white-ffffff, #fff);
    @include devices(largeMobile) {
        width: 100%;
        height: auto;
    }
}

.win-rate-section {
    width: 406px;
    height: 121px;
    border-radius: 18px;
    background-color: var(--pf-additional-calamansi-e-9-ffad, #e9ffad);
    @include devices(largeMobile) {
        width: 100%;
        height: auto;
    }
}

.statistics-main {
    .win-rate-main-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 35px;
        font-family: Figtree;
        font-weight: 500;
    }

    .win-rate-main-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 16px;
        font-family: Figtree;
        opacity: 0.5;
    }

    .tuner-graph {
        width: 200px;
    }

    .largest-win-main {
        width: 100%;
        height: 87px;
        border-radius: 18px;
        background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    }

    .largest-win-main-count {
        color: var(--pf-primary-white-ffffff, #fff);
        font-size: 22px;
        font-family: Figtree;
        font-weight: 500;
    }

    .green-grow-img {
        width: 14px;
        height: 14px;
    }

    .largest-win-main-label {
        color: var(--pf-primary-white-ffffff, #fff);
        font-size: 14px;
        font-family: Figtree;
        margin-left: 5px;
        opacity: 0.5;
    }

    .breakeven-main {
        width: 100%;
        height: 149px;
        border-radius: 18px;
        background-color: var(--pf-secondary-geyser-dae-5-e-3, #dae5e3);
    }

    .breakeven-main-calender {
        width: 41px;
        height: 46px;
        color: rgb(255, 255, 255);
    }

    .breakeven-main-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 30px;
        font-family: Figtree;
        font-weight: 500;
    }

    .breakeven-main-label {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        opacity: 0.6;
    }

    .not-trade-found {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 30px;
        font-family: Figtree;
        font-weight: 500;
    }

    .average-gain-main-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        text-align: center;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
    }

    .average-gain-main-label {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        text-align: center;
        font-size: 12px;
        font-family: Figtree;
        opacity: 0.5;
    }
}
