.stock-card-endUser {
    width: 100%;
    height: 70px;
    border-radius: 10px;
    background-color: rgb(245, 248, 248);

    .stock-card-logo-container {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .stock-card-symbol {
        color: rgb(31, 31, 31);
        font-size: 13px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 125%;
        margin-left: 10px;
    }

    .stock-card-amount {
        color: rgb(31, 31, 31);
        font-size: 11px;
        font-family: Figtree;
        position: absolute;
        top: 15px;
        left: 10px;
        white-space: nowrap;
    }

    .stock-card-values {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .font-size-15 {
            font-size: 15px;
        }

        .font-size-12 {
            font-size: 12px;
        }
    }
}
