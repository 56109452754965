@import 'global_scss/media.scss';

.not-added-payment-method {
    width: 490px;
    height: 265px;
    border-radius: 25px 25px 0px 0px;

    .bank-house-icon {
        width: 50px;
        height: 50px;
    }
    .text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 20px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;
        text-align: center;
    }

    .cancel-btn {
        color: rgb(31, 31, 31);
        background-color: rgb(255, 255, 255);
        border: 1px solid rgb(31, 31, 31);
    }
    .connect-btn {
        color: rgb(192, 255, 2);
        background-color: rgb(31, 31, 31);
    }

    .cancel-btn,
    .connect-btn {
        width: 200px;
        border-radius: 54px;
        height: 52px;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.7px;
        font-style: normal;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='not added payment method'] {
    @include devices(largeMobile) {
        width: auto !important;
        height: auto !important;
        .not-added-payment-method.p-3 {
            width: auto !important;
            height: auto !important;
        }
        button.btn.btn-sm.m-2.cancel-btn,
        .connect-btn {
            width: 135px;
        }
    }
}
