@import 'global_scss/media.scss';

.trending-stock-container {
    width: 50%;
    @include devices(largeMobile) {
        width: 100%;
        margin-top: 2rem;
    }
    .trending-container {
        border-radius: 20px;
        background-color: var(--pf-secondary-geyser-dae-5-e-3, #dae5e3);
        border: 8px solid var(--pf-additional-cultured-f-5-f-8-f-8, #f5f8f8);
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        p {
            margin-bottom: 0px;
        }
        .logo-container {
            width: 20%;
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
        .title-side {
            width: 80%;
            .ticker {
                color: #1f1f1f;
                font-size: 13px;
                font-family: Figtree;
                font-weight: 500;
                line-height: 125%;
                margin-left: 10px;
            }
            .sort-name {
                color: #1f1f1f;
                font-size: 11px;
                font-family: Figtree;
                position: absolute;
                top: 15px;
                left: 10px;
            }
        }
    }
}
