.setting-wrapper {
    height: 100%;
    border-radius: 25px;
    border: #ecf2f1;
    background-color: #fff;
    top: 1rem;
    .heading {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 20px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
        margin-left: 7px;
    }
}
