.manage-analyst-settings {
    .analyst-img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
    }

    .analyst-username {
        color: #1f1f1f;
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        margin-left: 10px;
    }

    .manage-analyst-trade-btn {
        width: 20%;
        height: 20%;
        margin-top: 2%;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        font-size: 14px;
        border-radius: 54px;
        background-color: #d3ff5b;
        color: #fff;
        border: 1px solid #d3ff5b;
        margin-left: 25%;
        padding: 2%;
    }

    .manage-analyst-remove-btn {
        width: 20%;
        height: 20%;
        margin-top: 2%;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        font-size: 14px;
        border-radius: 54px;
        background-color: red;
        color: #fff;
        border: 1px solid red;
        padding: 2%;
    }
}
