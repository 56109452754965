.image-container {
    display: flex;
    overflow-x: auto;
    height: 100%;
    flex-wrap: wrap;
    /* justify-content: space-evenly; */
}

.image-item {
    flex: 0 0 200px;
    margin-right: 10px;
    margin-bottom: 100px;
}

.image-item:last-child {
    margin-right: 0;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inputWidthWithToggle {
    width: 375px;
}

.inputWidthWithNotToggle {
    width: 375px;
}
