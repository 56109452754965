@import 'global_scss/media.scss';

.cardPortfolio {
    width: 300px;
    height: 300px;

    background-position: top left;
    background-repeat: repeat;
    overflow: hidden;
    margin-top: 10rem;
}

.bg-image {
    background-image: url('../../../public/assets/Portfolio/portfolio.png');
    background-size: cover;
    /* width: 100%;
  height: 100vh; */
    width: 842px;
    height: 468px;
}

.piechart-div {
    width: 100%;
    border-radius: 18px;
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    background: #fff;
}

.pie-chart {
    width: 400px;
    height: 400px;
}

.portfolio-influencer {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }
    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }

    .holding-margin {
        margin-right: 1rem;
        @include devices(largeMobile) {
            margin-right: 0rem;
        }
    }

    .middle-container {
        top: -3rem;
        @include devices(tablet) {
            top: 1rem;
        }
    }

    .row-container {
        @include devices(tablet) {
            margin: 1rem;
        }

        @include devices(largeMobile) {
            margin: 0rem;
        }
    }

    .holding-flex {
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }
    .hsbc-container {
        width: 100%;
        height: 172.957px;
        border-radius: 18px;
        background: rgb(255, 255, 255);
        margin-right: 1rem;
        @include devices(largeMobile) {
            margin-right: 0rem;
            width: 100%;
            margin-bottom: 1rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    .hsbc-flex {
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }

    .all-holdings-contain {
        @include devices(largeMobile) {
            flex-direction: column;
        }
    }

    .no-trades-found {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
    }
}

.default-broker-dropdown {
    border: none;
    background-color: #1f1f1f;
    width: 100%;
}

.portfolio-trade-history-model {
    width: 100%;
    height: 171px;
    border-radius: 25px 25px 0px 0px;
    background-color: rgb(255, 255, 255);

    .trade-history-label {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 18px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 24px;
    }

    .portfolio-hr-separator {
        opacity: 0.1;
    }

    .trade-history-stock-info {
        width: 48px;
        height: 48px;
        padding-top: 2px;
    }

    .trade-history-stock-logo {
        width: 48px;
        height: 48px;
        margin-left: 3px;
        border-radius: 287px;
    }

    .trade-history-left-margin {
        margin-left: 10px;
    }

    .trade-history-symbol {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: normal;

        .trade-history-price {
            color: rgb(31, 31, 31);
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
            margin-left: 5px;
        }

        .trade-history-assetType {
            color: rgb(31, 31, 31);
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
            margin-left: 5px;
            text-transform: capitalize;
        }

        .trade-history-date {
            color: rgb(31, 31, 31);
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
            margin-left: 5px;
        }

        .trade-history-side {
            color: rgb(113, 201, 1);
            font-size: 14px;
            font-family: Figtree;
            font-weight: 500;
            line-height: normal;
            margin-left: 5px;
            text-transform: uppercase;
        }
    }

    .trade-history-name {
        color: rgb(31, 31, 31);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        font-style: normal;
        opacity: 0.5;
        text-transform: capitalize;
    }

    .trade-history-left-margin-40px {
        margin-left: 40px;
    }

    .trade-history-order-label {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
    }

    .trade-history-order-value {
        color: rgb(255, 121, 46);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        bottom: 10px;
    }
}
