.trade-item {
    border: 1px solid rgb(190, 190, 190);
    border-radius: 10px;
    cursor: pointer;
    padding: 15px;
    transition: box-shadow 0.2s;
    &:hover {
        box-shadow: 2px 2px 10px rgba(73, 73, 73, 0.2);
    }
    p {
        font-size: 16px;
        font-style: normal;
        font-family: Figtree;
    }
}

.prev-btn {
    color: #d3ff5b;
    background-color: #1f1f1f;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 14px;
    font-style: normal;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    margin: 1rem;
}

.disable-btn {
    color: #d3ff5b91 !important;
    background-color: #1f1f1f70 !important;
}

.next-btn {
    color: #d3ff5b;
    background-color: #1f1f1f;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    margin: 1rem;
}
.submit-btn {
    color: #1f1f1f;
    background-color: #d3ff5b;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    font-style: normal;
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    line-height: normal;
    margin: 1rem;
    width: 27rem;
    height: 50px;
    &:hover {
        background-color: #d3ff5b8f;
        color: #1f1f1fa1;
    }
}
