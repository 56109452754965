.influencer-spotlight {
    width: 100%;
    border-radius: 20px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    border: var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);

    .influencer-profile-profile-picture {
        width: 64px;
        height: 64px;
    }

    .influencer-profile-twitter-picture {
        width: 13px;
        height: 10.563px;
    }

    .spotlight-twitter-count {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 500;
    }

    .spotlight-user-name {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
    }

    .spotlight-user-check {
        width: 13px;
        height: 13px;
    }

    .spotlight-return-label {
        color: rgb(31, 31, 31);
        font-size: 12px;
        font-family: Figtree;
        font-style: normal;
        font-weight: 400;
        opacity: 0.8;
    }

    .spotlight-green-area-container {
        width: 54.3px;
        height: 17px;
        border-radius: 48px;
        background: rgb(211, 255, 91);
        padding: 3px 10px;
    }

    .up-arrow-icon {
        width: 8.333px;
        height: 5px;
        margin-top: 4px;
        margin-right: 2px;
    }

    .spotlight-return-count {
        color: rgb(31, 31, 31);
        font-size: 10px;
        font-family: Figtree;
        font-weight: 600;
        letter-spacing: 0.7px;
    }
}
