.influencer-broker {
    .diamond-picture {
        width: 44px;
        height: 44px;
    }
    .default-container {
        width: 100%;
        border-bottom: 1px solid rgb(222, 226, 230);
    }
    .broker-name-margin {
        margin-left: 7px;
    }
    .common-label-color {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    }
    .color-green {
        color: green;
    }
    .color-blue {
        color: rgb(100, 162, 255);
    }
    .color-red {
        color: red;
    }
    .stock-label {
        font-size: 16px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }
    .stock-account-name {
        font-size: 14px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
    }
    .connected-label {
        opacity: 0.5;
        margin-left: 7px;
    }
    .heading-common-font {
        font-size: 14px;
        font-family: Figtree;
        font-weight: 600;
        font-style: normal;
        line-height: normal;
    }
    .broker-light-heading {
        opacity: 0.5;
        letter-spacing: 0.7px;
    }
    .new-broker-heading {
        cursor: pointer;
        letter-spacing: 0.7px;
    }
    .set-default {
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
        white-space: nowrap;
    }
}
