@import 'global_scss/media.scss';

.fetchrecenttrade-div {
    width: 100%;
    height: 78px;
    background-color: var(--pf-primary-white-ffffff, #fff);
    border: 1px solid var(--pf-additional-porcelain-ecf-2-f-1, #ecf2f1);
    border-radius: 18px;
}

.recent-trade-from-influencer {
    .trade-logo-recent {
        display: flex;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        // background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        align-items: center;
        justify-content: center;
        // background-color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        margin: auto;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .logo-from-recent-trade {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .recent-trade-ticker {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 125%;
    }

    .recent-trade-side {
        color: rgb(113, 201, 1);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 125%;
        text-transform: uppercase;
    }

    .recent-trade-value {
        color: rgb(31, 31, 31);
        font-size: 14px;
        font-family: Figtree;
        font-weight: 500;
        line-height: 125%;
    }

    .recent-trade-date {
        color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
        font-size: 12px;
        font-family: Figtree;
        line-height: 125%;
        text-align: right;
    }
}
