@import 'global_scss/media.scss';
.side-notification {
    .middle-content {
        bottom: 20px;
        width: 77%;
        margin-left: auto;

        @include devices(1440) {
            width: 61%;
            margin-left: 5rem;
        }

        @include devices(1280) {
            width: 53%;
        }
    }

    .time-duration {
        color: #8f8f8f;
        font-size: 14px;
        font-family: 'Figtree';
        font-weight: 400;
        font-style: normal;
        line-height: normal;
    }
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Notification Order Confirmation Modal'] {
    @include devices(largeMobile) {
        width: 100% !important;
    }
}
