.influencer-profile-update {
    width: 135px;
    height: 135px;
    border-radius: 200px;
}

.influencer-profile-edit {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    border: rgb(255, 255, 255);
    background-color: rgb(211, 255, 91);
    position: absolute;
    top: 6rem;
    left: 4rem;
    cursor: pointer;
}
.pencil-icon-edit {
    width: 26.4px;
    height: 26.4px;
    margin-left: 12px;
    cursor: pointer;
}

.input-control {
    border: none;
    border-radius: 0px;
    margin-right: 15px;
    border-bottom: 1px solid rgb(222, 226, 230);
}

.edit-tag {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 14px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    opacity: 0.5;
}

.orange-icon-influencer {
    width: 17px;
    height: 17px;
}
.select-changer {
    color: rgb(255, 121, 46);
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 10px;
    bottom: 2px;
}
.change-tag {
    color: rgb(100, 162, 255);
    font-size: 12px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    right: 10px;
}
.social-links {
    color: rgb(31, 31, 31);
    font-size: 14px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 10px;
    opacity: 0.5;
}
.add-platform {
    color: rgb(100, 162, 255);
    font-size: 14px;
    font-family: Figtree;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    right: 10px;
    cursor: pointer;
}

.down-angle {
    width: 12px;
    height: 12px;
}
.save-button {
    width: 311px;
    height: 52px;
    border-radius: 54px;
    background: rgb(31, 31, 31);
    font-size: 14px;
    font-family: Figtree;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    color: rgb(211, 255, 91);
    letter-spacing: 0.7px;
    margin-bottom: 2px;
}
