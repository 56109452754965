.filter-drop {
    height: 100%;
    border-radius: 25px 25px 0px 0px;
    overflow-y: scroll;
    .filter-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 600;
        line-height: normal;
        text-align: start;
        padding-left: 15px;
    }
    .popular-text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        padding-left: 15px;
    }
    .blue-tik-icon {
        img {
            width: 14px;
            height: 9px;
        }
    }
    .drop-item {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-style: normal;
        font-size: 16px;
        font-family: Figtree;
        font-weight: 400;
        line-height: normal;
        text-align: start;
        padding-left: 15px;
        opacity: 0.5;
    }
}
.highest-data {
    cursor: 'pointer';
}
