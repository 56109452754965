.main-heading-label {
    color: rgb(143, 143, 143);
    font-size: 16px;
    font-family: Figtree;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
}

.portfolio-view-all {
    color: rgb(31, 31, 31);
    font-size: 16px;
    font-family: Figtree;
    font-weight: 500;
    line-height: normal;
    font-style: normal;
    cursor: pointer;
}
