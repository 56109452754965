.notification-dropdown-container {
    .notification-list-container {
        right: 0;
        margin-right: -4rem;
        width: 350px;
        height: 20rem;
        overflow-x: hidden;
        overflow-y: scroll;
        border-radius: 25px;
    }

    .col-3.col-lg-3.col-md-3.col-sm-3.side-notification-bar {
        margin-left: 0;
        padding: 0;
        margin-top: 1rem;
    }
    .side-notification {
        background-color: white;
        box-shadow: 17px 20px #ffffff;
    }
}
