.subscription-following {
    height: 760px;
    border-radius: 25px;
    border: rgb(236, 242, 241);
    background-color: rgb(255, 255, 255);
    top: 1rem;
    .header-text {
        font-size: 16px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
        text-align: center;
    }
    .hr-line {
        width: 100%;
        stroke-width: 2px;
    }
    .total-text {
        color: rgb(143, 143, 143);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
    }
    .search-icon {
        width: 24px;
        height: 24px;
    }
}

.influencer-user-card {
    margin-left: 15px;
    .user-image {
        width: 48px;
        height: 48px;
        border-radius: 48px;
    }

    .orange-icon {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 2rem;
        left: 2px;
    }

    .user-name {
        color: rgb(31, 31, 31);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 500;
        font-style: normal;
        line-height: normal;
        cursor: pointer;
    }
}
