@import 'global_scss/media.scss';

.portfolio-trade-history {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }
    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }

    .trending-card-middle {
        width: 50%;
        @include devices(largeMobile) {
            width: 100%;
        }
    }
}
