.end-user-card-change {
    .total-change-card {
        width: 257px;
        height: 62px;
        border-radius: 10px;
        background-color: rgb(236, 242, 241);
    }

    .portfolio-equitiesValue {
        color: rgb(31, 31, 31);
        font-size: 16px;
        font-family: Figtree;
        font-weight: 600;
    }

    .portfolio-equities-label {
        color: var(--pf-additional-aluminium-8-f-8-f-8-f, #8f8f8f);
        font-size: 12px;
        font-family: Figtree;
        font-weight: 500;
        opacity: 0.5;
    }

    .separator-line {
        height: 38px;
        margin-top: 10px;
        img {
            height: 38px;
        }
    }

    .upload-icon-top-trades {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        background-color: rgb(100, 162, 255);
        img {
            width: 21.29px;
            height: 21.29px;
        }
    }
}
