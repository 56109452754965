@import 'global_scss/media.scss';

.trade-option-call {
    width: 100%;
    border-radius: 25px 25px 0px 0px;
    background-color: rgb(255, 255, 255);
}

.ReactModal__Content.ReactModal__Content--after-open[aria-label='Trade Option Call Modal'] {
    @include devices(largeMobile) {
        width: 95% !important;
    }
}
