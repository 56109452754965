@import 'global_scss/media.scss';
.image-container {
    display: flex;
    overflow-x: auto;
    height: 100%;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.image-item {
    flex: 0 0 200px;
    margin-right: 10px;
    margin-bottom: 100px;
}

.image-item:last-child {
    margin-right: 0;
}

.image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}

.trending-stocks {
    overflow: hidden;
    @include devices(tablet) {
        overflow: auto;
    }
    .black-row {
        width: 100%;
        min-height: 245px;
        background: #1f1f1f;
        @include devices(largeMobile) {
            min-height: 323px;
        }
    }

    .search-bar {
        @include devices(largeMobile) {
            margin-top: 7rem;
        }
    }
}
