body {
    font-family: 'Lato', sans-serif;
    background-color: black;
    /* background-size: cover; */
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 30px;
}

.sidenav a {
    padding: 8px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

.totalInv {
    display: flex;
    justify-content: space-between;
    color: #fffff7;
}

.expo-influencer {
    display: flex;
    justify-content: space-between;
    color: white;
}

.glow-on-hover {
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: black;
    background-color: #fff;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 25%;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: #000;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}

.image-gallery {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
}

.image-gallery-item {
    flex: 0 0 calc(33.33%);
    position: relative;
    border-radius: 25px;
    padding: 1rem;
}

.image-gallery-item p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 200px;
}

.influencer-data-item {
    flex: 0 0 calc(33.33%);
    max-width: calc(33.33%);
    height: 500px; /* Set a fixed height for all images */
    position: relative;
    border-radius: 25px;
}

.image-gallery-item img {
    display: block;
    width: 90%;
    height: 90%;
    object-fit: cover; /* Scale the image to cover the entire container */
    border-radius: 25px;
}

.image-gallery-item::after {
    /* content: ""; */
    display: block;
    /* Set the aspect ratio of the pseudo-element */
}
