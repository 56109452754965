.side-bar-heading {
    width: 321px;
    height: 64px;
    border-radius: 14px;
    top: 17rem;
    .cursor-pointer {
        cursor: pointer;
    }

    .text {
        color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
        font-size: 15px;
        font-family: Figtree;
        font-weight: 400;
        font-style: normal;
        line-height: normal;
        margin-left: 7px;
    }
}

.image-icon-sidebar {
    width: 24px;
    height: 24px;
}

.accordion-text {
    color: var(--pf-primary-black-1-f-1-f-1-f, #1f1f1f);
    font-size: 15px;
    font-family: Figtree;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    margin-left: 7px;
}
